import Vue from "vue"
import Vuex from "vuex"
import user from "./modules/user"
import auth from "./modules/auth"
import profils from "./modules/profils"
import fournisseurs from "./modules/fournisseurs"
import uxHelpers from "./modules/uxHelpers"
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production"

export default new Vuex.Store({
  modules: {
    user,
    auth,
    profils,
    fournisseurs,
    uxHelpers
  },
  plugins: [createPersistedState({
    key: "persisted-vuex",
    paths: [
      'user',
      'auth',
      'profils',
      'fournisseurs'
    ]
  })],
  strict: debug
})

