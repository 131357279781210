<template>
  <div class="container-fluid">
    <BarreFiltres :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" />
    <BarreActions :actions="actions" @appliquer-action="executerAction($event)" />
    <BarreInformations :informations="informations" @visibility-changed="barreInformationAffichee=$event" />  <!-- "information" doit être computed -->

    <ModalImportFichierCotation ref="modalImportFichierCotation" :datePropoId="dateIdSelectionnee" :nomFichier="nomDuFichier" :contenuFichier="contenuDuFichier" @rechargerPropo="chargerPropositionsApresImport()" @messageFT="afficherMessageFicheTechnique"/>
    <ModalImportFichesTechniques ref="modalImportFichesTechniques" :datePropoId="dateIdSelectionnee" :propoArticleId="propoArticleSelectionnee" :familleId="familleIdSelectionnee" :nomFichier="nomDuFichier" :contenuFichier="contenuDuFichier" @rechargerPropo="chargerPropositionsApresImport()"/>
    <ModalAfficheDetailFT ref="modalAfficheDetailFT" :listeErreurs="listeErreurFicheTechnique" />

    <!--input pour l'import du fichier de cotation-->
    <input type="file" accept=".xls,.xlsx,.zip" @change="chargerFichierCotation($event)" style="display: none" ref="importCotations" />
    <!--input pour l'import des fiches TEchniques via un zip ou un pdf-->
    <input type="file" accept=".pdf,.zip" @change="chargerFichesTechniques($event)" style="display: none" ref="importFicheTechnique" />

    <div v-if="!afficherTableauPropositions" class="card table-scroll" :class="[{'height-responsive-100':!barreInformationAffichee, 'height-responsive-3b':barreInformationAffichee}]">
      <div class="card-body">
        Veuillez sélectionner une date et une famille s'il vous plaît.
      </div>
    </div>
    <div v-else class="card table-scroll" :class="[{'height-responsive-100':!barreInformationAffichee, 'height-responsive-3b':barreInformationAffichee}]">
      <div class="card-header table-header">
        <table class="table table-bordered table-sm text-center table-header">
          <colgroup>
            <col>
            <!-- <col width="75"> -->
            <col class="col-fixed-width-150-sm col-fixed-width-250-lg col-fixed-width-400-xl">
            <col width="75">
            <col class="col-fixed-width-100-sm col-fixed-width-150-lg col-fixed-width-200-xl">
            <col width="140">
            <col width="100">
            <col width="80">
            <col width="120">
            <col width="80">
            <col width="120">
            <col width="135">
            <col width="65">
            <col width="40">
          </colgroup>
          <thead>
            <th>Produit Agap'pro</th>
            <!-- <th>UC</th> -->
            <th>Désignation</th>
            <th>Réf.</th>
            <th>Marque</th>
            <th>Prix HT / UC</th>
            <th>Nb UC / UF</th>
            <th>Unité Fact.</th>
            <th>Prix UF</th>
            <th>UC EDI</th>
            <th>Prix UC EDI</th>
            <th>Commentaire</th>
            <th>FT</th>
            <th></th>
          </thead>
        </table>
      </div>
      <div v-if="!filtres.statuts.selection && !contientPropositionsModifiables(tableauPropositions)" class="card-body">
        Aucune proposition à traiter.
      </div>
      <div v-else class="table-body">
        <table class="table table-sm text-center">
          <colgroup>
            <col>
            <!-- <col width="75"> -->
            <col class="col-fixed-width-150-sm col-fixed-width-250-lg col-fixed-width-400-xl">
            <col width="75">
            <col class="col-fixed-width-100-sm col-fixed-width-150-lg col-fixed-width-200-xl">
            <col width="140">
            <col width="100">
            <col width="80">
            <col width="120">
            <col width="80">
            <col width="120">
            <col width="135">
            <col width="65">
            <col width="35">
          </colgroup>
          <tbody>
            <template v-for="(categorie, iCategorie) in tableauPropositions.categories.filter(c => c.sousCategories.some(sc => sc.produits.some(p => filtrer(p))))">
              <tr class="table-header-tertiary" :key="'categorie-'+iCategorie">
                <td v-if="pourcentageFluctuation(totalCategorie(categorie).totalPropo, totalCategorie(categorie).totalActif) != 0" colspan="13" class="aligner-gauche">
                  {{ categorie.categorie }} ({{ afficherPourcentageFluctuation(totalCategorie(categorie).totalPropo, totalCategorie(categorie).totalActif) }})<i :class="['ml-1',{'far fa-smile':pourcentageFluctuation(totalCategorie(categorie).totalPropo, totalCategorie(categorie).totalActif) < 0},{'far fa-frown':pourcentageFluctuation(totalCategorie(categorie).totalPropo, totalCategorie(categorie).totalActif) > 0}]"></i>
                </td>
                <td v-else colspan="13" class="aligner-gauche">
                  {{ categorie.categorie }}
                </td>
              </tr>
              <template v-for="(sousCategorie, iSousCategorie) in categorie.sousCategories.filter(sc => sc.produits.some(p => filtrer(p)))">
                <tr class="table-header-secondary" :key="'ssCat-'+iCategorie+'categ'+iSousCategorie">
                  <td colspan="13" class="aligner-gauche pl-3">
                    {{ sousCategorie.sousCategorie }}
                  </td>
                </tr>
                <template v-for="(produit, iProduit) in sousCategorie.produits.filter(p => filtrer(p))">
                  <!-- Ligne du prix Actif -->
                  <tr :key="'prodAgap-'+'ssCat'+iCategorie+'categ'+iSousCategorie+'prod'+iProduit" :class="[{'ligne-selectionnee':produit.produitAgapId === produitSelectionneId}]">
                    <td @click="deplacerCurseur(produit.produitAgapId, 0)" class="aligner-gauche">
                      <span class="note">[{{ produit.codeProduitAgap }}] </span>{{ produit.nomProduitAgap }}
                    </td>
                    <td colspan="12" class="td-table">
                      <table class="table table-bordered table-sm text-center">
                        <colgroup>
                          <col class="col-fixed-width-150-sm col-fixed-width-250-lg col-fixed-width-400-xl">
                          <col width="75">
                          <col class="col-fixed-width-100-sm col-fixed-width-150-lg col-fixed-width-200-xl">
                          <col width="140">
                          <col width="100">
                          <col width="80">
                          <col width="120">
                          <col width="80">
                          <col width="120">
                          <col width="135">
                          <col width="65">
                          <col width="35">
                        </colgroup>
                        <tbody>
                          <!-- Ligne du Tarif Actif -->
                          <tr :key="'prix-'+'ssCat'+iCategorie+'categ'+iSousCategorie+'prod'+iProduit">
                            <td @click="selectionnerProduit(produit.produitAgapId)" class="aligner-gauche">
                              {{ propositionFournisseur(produit)?.prixActif?.designation }}
                            </td>
                            <td @click="selectionnerProduit(produit.produitAgapId)">
                              {{ propositionFournisseur(produit)?.prixActif?.reference }}
                            </td>
                            <td @click="selectionnerProduit(produit.produitAgapId)">
                              {{ propositionFournisseur(produit)?.prixActif?.marque }}
                            </td>
                            <td @click="selectionnerProduit(produit.produitAgapId)">
                              <span v-if="propositionFournisseur(produit)?.prixActif">{{ propositionFournisseur(produit)?.prixActif?.prixUnitaireHT | afficherPrix }} / {{ produit.uniteCotation }}</span>
                              <div v-if="propositionFournisseur(produit)?.propositionEnCours?.prixUnitaireHT && propositionFournisseur(produit)?.prixActif?.prixUnitaireHT" :class="['bold600',{'text-success':pourcentageFluctuation(propositionFournisseur(produit)?.propositionEnCours?.prixUnitaireHT, propositionFournisseur(produit)?.prixActif?.prixUnitaireHT) < 0},{'text-error':pourcentageFluctuation(propositionFournisseur(produit)?.propositionEnCours?.prixUnitaireHT, propositionFournisseur(produit)?.prixActif?.prixUnitaireHT) > 0}]"><i :class="['ml-1',{'far fa-meh':pourcentageFluctuation(propositionFournisseur(produit)?.propositionEnCours?.prixUnitaireHT, propositionFournisseur(produit)?.prixActif?.prixUnitaireHT)===0},{'far fa-smile':pourcentageFluctuation(propositionFournisseur(produit)?.propositionEnCours?.prixUnitaireHT, propositionFournisseur(produit)?.prixActif?.prixUnitaireHT) < 0},{'far fa-frown':pourcentageFluctuation(propositionFournisseur(produit)?.propositionEnCours?.prixUnitaireHT, propositionFournisseur(produit)?.prixActif?.prixUnitaireHT) > 0}]"></i> ({{ afficherPourcentageFluctuation(propositionFournisseur(produit)?.propositionEnCours?.prixUnitaireHT, propositionFournisseur(produit)?.prixActif?.prixUnitaireHT) }})</div>
                            </td>
                            <td @click="selectionnerProduit(produit.produitAgapId)">
                              {{ afficherPCBetUC(propositionFournisseur(produit)?.prixActif?.pcb, produit.uniteCotation) }}
                            </td>
                            <td @click="selectionnerProduit(produit.produitAgapId)">
                              {{ propositionFournisseur(produit)?.prixActif?.uniteFacturation }}
                            </td>
                            <td @click="selectionnerProduit(produit.produitAgapId)">
                              {{ afficherPrixConverti(propositionFournisseur(produit)?.prixActif?.prixUnitaireHT, propositionFournisseur(produit)?.prixActif?.pcb, produit.uniteCotation, propositionFournisseur(produit)?.prixActif?.uniteFacturation) }}
                            </td>
                            <td @click="selectionnerProduit(produit.produitAgapId)">
                              {{ propositionFournisseur(produit)?.prixActif?.uniteCommandeEDI }}
                            </td>
                            <td @click="selectionnerProduit(produit.produitAgapId)">
                              {{ afficherPrixConverti(propositionFournisseur(produit)?.prixActif?.prixUnitaireHT, propositionFournisseur(produit)?.prixActif?.pcb, produit.uniteCotation, propositionFournisseur(produit)?.prixActif?.uniteCommandeEDI) }}
                            </td>
                            <td @click="selectionnerProduit(produit.produitAgapId)"></td>
                            <td @click="selectionnerProduit(produit.produitAgapId)"></td>
                            <td @click="selectionnerProduit(produit.produitAgapId)">
                              <button :disabled="!propositionEstVide(propositionFournisseur(produit)?.propositionEnCours) || propositionEstVide(propositionFournisseur(produit)?.prixActif) || !estModifiable(propositionFournisseur(produit)?.propositionEnCours)" class="btn btn-primary btn-sm" @click="selectionnerProduit(produit.produitAgapId); alimenterProposition(propositionFournisseur(produit)?.propositionEnCours, propositionFournisseur(produit)?.prixActif)"><i class="fas fa-clone"></i></button> 
                            </td>
                          </tr>
                          <template v-for="(proposition, iProposition) in propositionFournisseur(produit)?.historiquePropositions">
                            <tr v-if="filtres.afficherHistoriquePropositions.selection" :key="'histoPropo-'+'ssCat'+iCategorie+'categ'+iSousCategorie+'prod'+iProduit+'propo'+iProposition">
                              <!-- Ligne des historiques des propositions -->
                              <td class="ligne-desactivee aligner-gauche" @click="selectionnerProduit(produit.produitAgapId)" >
                                {{ proposition.designation }}
                              </td>
                              <td class="ligne-desactivee" @click="selectionnerProduit(produit.produitAgapId)">
                                {{ proposition.reference }}
                              </td>
                              <td class="ligne-desactivee" @click="selectionnerProduit(produit.produitAgapId)">
                                {{ proposition.marque }}
                              </td>
                              <td class="ligne-desactivee" @click="selectionnerProduit(produit.produitAgapId)">
                                {{ proposition.prixUnitaireHT | afficherPrix }} / {{ produit.uniteCotation }}
                              </td>
                              <td class="ligne-desactivee" @click="selectionnerProduit(produit.produitAgapId)">
                                {{ afficherPCBetUC(proposition.pcb, produit.uniteCotation) }}
                              </td>
                              <td class="ligne-desactivee" @click="selectionnerProduit(produit.produitAgapId)">
                                {{ proposition.uniteFacturation }}
                              </td>
                              <td class="ligne-desactivee" @click="selectionnerProduit(produit.produitAgapId)">
                                {{ afficherPrixConverti(proposition.prixUnitaireHT, proposition.pcb, produit.uniteCotation, proposition.uniteFacturation) }}
                              </td>
                              <td class="ligne-desactivee" @click="selectionnerProduit(produit.produitAgapId)">
                                {{ proposition.uniteCommandeEDI }}
                              </td>
                              <td class="ligne-desactivee" @click="selectionnerProduit(produit.produitAgapId)">
                                {{ afficherPrixConverti(proposition.prixUnitaireHT, proposition.pcb, produit.uniteCotation, proposition.uniteCommandeEDI) }}
                              </td>
                              <td class="ligne-desactivee" @click="selectionnerProduit(produit.produitAgapId)">
                                {{ proposition.commentaireFournisseur }}
                              </td>
                              <td class="ligne-desactivee" @click="selectionnerProduit(produit.produitAgapId)"></td>
                              <td class="ligne-desactivee" @click="selectionnerProduit(produit.produitAgapId)"></td>
                            </tr>
                            <tr v-if="filtres.afficherHistoriquePropositions.selection && proposition.retourAgap" :key="'histoPropoAgap-'+'ssCat'+iCategorie+'categ'+iSousCategorie+'prod'+iProduit+'propo'+iProposition">
                              <td colspan="12" class="aligner-gauche ligne-desactivee" @click="selectionnerProduit(produit.produitAgapId)">
                                {{ proposition.retourAgap }}
                              </td>
                            </tr>
                          </template>
                          <!-- Ligne des propositions à saisir -->
                          <tr v-if="estVisible(produit)" :class="[{'ligne-modifiee':estModifie(produit)}]" :key="'proposition-'+'ssCat'+iCategorie+'categ'+iSousCategorie+'prod'+iProduit" :style="'background-color:' + couleurHexCss(propositionFournisseur(produit)?.propositionEnCours?.couleur, 25)"> <!-- :style="produit.propositionEnCours?.couleur ? ('background-color:#' + produit.propositionEnCours.couleur) : ''" -->
                            <td class="aligner-gauche" :style="styleBordure('border', produit, 1, 10)" @click="deplacerCurseur(produit.produitAgapId, 0)">
                              <span v-if="estModifiable(propositionFournisseur(produit)?.propositionEnCours)" class="flex-center-space-between">
                                <input :id="'col0' + produit.produitAgapId" type="text"  class="form-control mr-1" v-model.lazy="propositionFournisseur(produit).propositionEnCours.designation" maxlength="80" @focus="$event.target.select()" @keyup="deplacerCurseurTexte(produit.produitAgapId, 0, $event)">
                                <i title="Valeur obligatoire" :class="champObligatoire(produit, propositionFournisseur(produit).propositionEnCours.designation, propositionFournisseur(produit)?.propositionEnCours?.designationObligatoire)"></i>
                              </span>
                              <span v-else>{{ propositionFournisseur(produit)?.propositionEnCours?.designation }}</span>
                            </td>
                            <td :style="styleBordure('border', produit, 1, 10)" @click="deplacerCurseur(produit.produitAgapId, 1)">
                              <span v-if="estModifiable(propositionFournisseur(produit)?.propositionEnCours)" class="flex-center-space-between">
                                <input :id="'col1' + produit.produitAgapId" type="text" class="form-control" v-model.lazy="propositionFournisseur(produit).propositionEnCours.reference" maxlength="80" @focus="$event.target.select()" @keyup="deplacerCurseurTexte(produit.produitAgapId, 1, $event)">
                                <i title="Valeur obligatoire" :class="champObligatoire(produit, propositionFournisseur(produit).propositionEnCours.reference, propositionFournisseur(produit)?.propositionEnCours?.referenceObligatoire)"></i>
                              </span>
                              <span v-else>{{ propositionFournisseur(produit)?.propositionEnCours?.reference }}</span>
                            </td>
                            <td :style="styleBordure('border', produit, 1, 10)" @click="deplacerCurseur(produit.produitAgapId, 2)">
                              <span v-if="estModifiable(propositionFournisseur(produit)?.propositionEnCours)" class="flex-center-space-between">
                                <input :id="'col2' + produit.produitAgapId" type="text" class="form-control" v-model.lazy="propositionFournisseur(produit).propositionEnCours.marque" maxlength="80" @focus="$event.target.select()" @keyup="deplacerCurseurTexte(produit.produitAgapId, 2, $event)">
                                <i title="Valeur obligatoire" :class="champObligatoire(produit, propositionFournisseur(produit).propositionEnCours.marque, propositionFournisseur(produit)?.propositionEnCours?.marqueObligatoire)"></i>
                              </span>
                              <span v-else>{{ propositionFournisseur(produit)?.propositionEnCours?.marque }}</span>
                            </td>
                            <td :style="styleBordure('border', produit, 1, 10)" @click="deplacerCurseur(produit.produitAgapId, 3)">
                              <label v-if="estModifiable(propositionFournisseur(produit)?.propositionEnCours)" class="flex-end h-100 p-0 m-0">
                                <span class="flex-center-space-between">
                                  <InputNumber :id="'col3' + produit.produitAgapId" class="form-control" v-model="propositionFournisseur(produit).propositionEnCours.prixUnitaireHT" :data-lignepid="produit.produitAgapId" typeFiltre="montant" decimales="6" :zeroSiVide="false" :fixBlur="true" @move="deplacerCurseur(produit.produitAgapId, 3, $event)" @focus="selectionnerProduit(produit.produitAgapId)" />
                                  <span class="input-group-text">€ / {{ produit.uniteCotation }}</span>
                                  <i title="Valeur obligatoire" :class="champObligatoire(produit, propositionFournisseur(produit).propositionEnCours.prixUnitaireHT, propositionFournisseur(produit)?.propositionEnCours?.prixObligatoire)"></i>
                                </span>
                              </label>
                              <span v-else>{{ propositionFournisseur(produit)?.propositionEnCours?.prixUnitaireHT | afficherPrix }}</span>
                            </td>
                            <td :style="styleBordure('border', produit, 1, 10)" @click="deplacerCurseur(produit.produitAgapId, 4)">
                              <label v-if="estModifiable(propositionFournisseur(produit)?.propositionEnCours)" class="flex-end h-100 p-0 m-0">
                                <span class="flex-center-space-between">
                                  <InputNumber :id="'col4' + produit.produitAgapId" class="form-control" v-model="propositionFournisseur(produit).propositionEnCours.pcb" :data-lignepid="produit.produitAgapId" typeFiltre="quantite" :zeroSiVide="false" :fixBlur="true" @move="deplacerCurseur(produit.produitAgapId, 4, $event)" @focus="selectionnerProduit(produit.produitAgapId)" />
                                  <span class="input-group-text">{{ produit.uniteCotation }}</span>
                                  <i title="Valeur obligatoire" :class="champObligatoire(produit, propositionFournisseur(produit).propositionEnCours.pcb, propositionFournisseur(produit)?.propositionEnCours?.pcbObligatoire)"></i>
                                </span>
                              </label>
                              <span v-else>{{ afficherPCBetUC(propositionFournisseur(produit)?.propositionEnCours?.pcb, produit.uniteCotation) }}</span>
                            </td>
                            <td :style="styleBordure('border', produit, 1, 10)" @click="deplacerCurseur(produit.produitAgapId, 5)">
                              <span v-if="estModifiable(propositionFournisseur(produit)?.propositionEnCours)" class="flex-center-space-between">
                                <input :id="'col5' + produit.produitAgapId" type="text" class="form-control" v-model.lazy="propositionFournisseur(produit).propositionEnCours.uniteFacturation" maxlength="80" @focus="$event.target.select()" @keyup="deplacerCurseurTexte(produit.produitAgapId, 5, $event)">
                                <i title="Valeur obligatoire" :class="champObligatoire(produit, propositionFournisseur(produit).propositionEnCours.uniteFacturation, propositionFournisseur(produit)?.propositionEnCours?.uniteFacturationFournisseurObligatoire)"></i>
                              </span>
                              <span v-else>{{ propositionFournisseur(produit)?.propositionEnCours?.uniteFacturation }}</span>
                            </td>
                            <td :style="styleBordure('border', produit, 1, 10)" @click="deplacerCurseur(produit.produitAgapId, 5)">
                              {{ afficherPrixConverti(propositionFournisseur(produit)?.propositionEnCours?.prixUnitaireHT, propositionFournisseur(produit)?.propositionEnCours?.pcb, produit.uniteCotation, propositionFournisseur(produit)?.propositionEnCours?.uniteFacturation) }}
                            </td>
                            <td :style="styleBordure('border', produit, 1, 10)" @click="deplacerCurseur(produit.produitAgapId, 6)">
                              <span v-if="estModifiable(propositionFournisseur(produit)?.propositionEnCours)" class="flex-center-space-between">
                                <input :id="'col6' + produit.produitAgapId" type="text" class="form-control" v-model.lazy="propositionFournisseur(produit).propositionEnCours.uniteCommandeEDI" maxlength="80" @focus="$event.target.select()" @keyup="deplacerCurseurTexte(produit.produitAgapId, 6, $event)">
                                <i title="Valeur obligatoire" :class="champObligatoire(produit, propositionFournisseur(produit).propositionEnCours.uniteCommandeEDI, propositionFournisseur(produit)?.propositionEnCours?.ucediFournisseurObligatoire)"></i>
                              </span>
                              <span v-else>{{ propositionFournisseur(produit)?.propositionEnCours?.uniteCommandeEDI }}</span>
                            </td>
                            <td :style="styleBordure('border', produit, 1, 10)" @click="deplacerCurseur(produit.produitAgapId, 6)">
                              {{ afficherPrixConverti(propositionFournisseur(produit)?.propositionEnCours?.prixUnitaireHT, propositionFournisseur(produit)?.propositionEnCours?.pcb, produit.uniteCotation, propositionFournisseur(produit)?.propositionEnCours?.uniteCommandeEDI) }}
                            </td>
                            <td :style="styleBordure('border', produit, 1, 10)" @click="deplacerCurseur(produit.produitAgapId, 7)">
                              <span v-if="estModifiable(propositionFournisseur(produit)?.propositionEnCours)" class="flex-center-space-between">
                                <input :id="'col7' + produit.produitAgapId" type="text" class="form-control" v-model.lazy="propositionFournisseur(produit).propositionEnCours.commentaireFournisseur" maxlength="80" @focus="$event.target.select()" @keyup="deplacerCurseurTexte(produit.produitAgapId, 7, $event)">
                                <i title="Valeur obligatoire" :class="champObligatoire(produit, propositionFournisseur(produit).propositionEnCours.commentaireFournisseur, propositionFournisseur(produit)?.propositionEnCours?.commentaireFournisseur)"></i>
                              </span>
                              <span v-else>{{ propositionFournisseur(produit)?.propositionEnCours?.commentaireFournisseur }}</span>
                            </td>
                            <td :style="styleBordure('border', produit, 1, 10)">
                              <span v-if="!propositionEstVide(propositionFournisseur(produit)?.propositionEnCours)" class="flex-center-space-between">
                                <button :disabled="!propositionFournisseur(produit)?.propositionEnCours?.ficheTechniqueAzureOk || !propositionFournisseur(produit)?.propositionEnCours?.id || (propositionFournisseur(produit)?.propositionEnCours?.id && !propositionEstVide(propositionFournisseur(produit)?.propositionEnCours) && !propositionFournisseur(produit)?.propositionEnCours?.ficheTechniqueAzureOk)" class="btn btn-primary btn-sm" @click="ouvrirFicheTechnique(propositionFournisseur(produit)?.propositionEnCours.id)"><i class="fas fa-eye"></i></button>
                                <button :disabled="!propositionFournisseur(produit)?.propositionEnCours?.id || !propositionFournisseur(produit)?.propositionEnCours?.reference || !estModifiable(propositionFournisseur(produit)?.propositionEnCours)" class="btn btn-primary btn-sm" :title="'Importer la fiche technique pour la référence ' + propositionFournisseur(produit)?.propositionEnCours?.reference" @click="importerUneFicheTechnique(propositionFournisseur(produit)?.propositionEnCours?.id)"><i class="fas fa-upload"></i></button>
                                <i title="Valeur obligatoire" :class="champObligatoire(produit, propositionFournisseur(produit)?.propositionEnCours?.ficheTechniqueAzureOk, propositionFournisseur(produit)?.propositionEnCours?.ftObligatoire)"></i>
                              </span>
                            </td>
                            <td :style="styleBordure('border', produit, 1, 10, false)">
                              <button :disabled="(propositionEstVide(propositionFournisseur(produit)?.propositionEnCours) && !(afficherErreurs && estVisible(produit) && propositionFournisseur(produit)?.retourAgap)) || !estModifiable(propositionFournisseur(produit)?.propositionEnCours)" class="btn btn-danger btn-sm" @click="selectionnerProduit(produit.produitAgapId); viderProposition(propositionFournisseur(produit))"><i class="fas fa-trash"></i></button> 
                            </td>
                          </tr>
                          <!-- Ligne du ou des messages d'erreur de la proposition -->
                          <tr v-if="afficherErreurs && estVisible(produit) && propositionFournisseur(produit)?.retourAgap" :key="'agap-'+'ssCat'+iCategorie+'categ'+iSousCategorie+'prod'+iProduit" :style="'background-color:' + couleurHexCss(propositionFournisseur(produit)?.propositionEnCours?.couleur, 15)">
                            <td colspan="12" :class="['aligner-gauche', {'text-error':estModifiable(propositionFournisseur(produit)?.propositionEnCours)}, {'text-note':!estModifiable(propositionFournisseur(produit)?.propositionEnCours)}]" :style="styleBordure('border', produit, 1, 10)" @click="selectionnerProduit(produit.produitAgapId)">
                              {{ propositionFournisseur(produit)?.retourAgap }}
                            </td>
                          </tr>
                          <!-- Ligne du ou des messages d'avertissement de la proposition, non affiché si propo en lecture seule -->
                          <tr v-if="afficherErreurs && estVisible(produit) && propositionFournisseur(produit)?.warningAgap && estModifiable(propositionFournisseur(produit)?.propositionEnCours)" :key="'warningAgap-'+'ssCat'+iCategorie+'categ'+iSousCategorie+'prod'+iProduit" :style="'background-color:' + couleurHexCss(propositionFournisseur(produit)?.propositionEnCours?.couleur, 15)">
                            <td colspan="12" class="aligner-gauche text-note" :style="styleBordure('border', produit, 1, 10)" @click="selectionnerProduit(produit.produitAgapId)">
                              <i class="fas fa-exclamation-triangle text-warning mr-2"></i>{{ propositionFournisseur(produit)?.warningAgap }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import BarreFiltres from '@/components/Divers/BarreFiltres'
import BarreActions from '@/components/Divers/BarreActions'
import BarreInformations from '@/components/Divers/BarreInformations'
import InputNumber from '@/components/Inputs/InputNumber'
import ModalImportFichierCotation from '../components/ModalImportFichierCotation.vue'
import ModalImportFichesTechniques from '../components/ModalImportFichesTechniques.vue'
import ModalAfficheDetailFT from '../components/ModalAfficheDetailFT.vue'
import { connecteData } from "@/mixins/connecteData"
import { alerteDonneesModifiees } from "@/mixins/index"
import { obtenirStatutsPropositions, obtenirDatesPropositionsOuvertes, obtenirFamillesProposition, obtenirPropositions, enregistrerPropositions, envoyerPropositions, obtenirTrameCotation, obtenirFicheTechnique } from '@/api/gpao/propositions'
import { formatHumanDateAvecSlashes, formatageDate } from '../mixins/dateFormats'
import { arrondiMercuriale, plurielUC, arrondiPrixPCB, Nombre, deplacerCurseur, calculerIndiceDeplacement, convertirPourcentageToHex, rechercherMultiTexte, arrondiPourcentageDifference, creerMessageBarreInformation } from '../helpers/utils'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import couleurs from '@/assets/css/couleurs.scss'

export default {
  name: "PropositionsMercuComparative",
  mixins: [ connecteData, alerteDonneesModifiees, formatHumanDateAvecSlashes, formatageDate ],
  components: {
    BarreFiltres,
    BarreActions,
    BarreInformations,
    InputNumber,
    ModalImportFichierCotation,
    ModalImportFichesTechniques,
    ModalAfficheDetailFT
  },
  data() {
    return {
      key: 0,
      selectedFile: null,
      formData: null,
      propoArticleFournId: null,
      statuts: [],
      propositionSelectionnee: null,
      familleSelectionnee: null,
      donneesInitiales: null,
      tableauPropositions: null,
      produitSelectionneId: null,
      listeProduitsModifies: [],
      afficherDatesFermees: false,
      messageImportFicheTechnique: "",
      importFicheTechniqueTermine: true,
      barreInformationAffichee: false,
      blobFt: null,
      filtres: {
        dateActivation: {
          label: "Date d'application",
          champ: "select",
          width: "210px",
          options: [],
          selection: null,
          initialiser(contexte, options) {
            contexte.filtres.dateActivation.options = options
          },
          async filtrer(contexte) {
            let filtreDateActivation = contexte.filtres.dateActivation

            if (filtreDateActivation.selection.valeur < 0) {
              filtreDateActivation.selection = contexte.propositionSelectionnee
              contexte.chargerDatesPropositions(contexte.fournisseurCourantId, !contexte.afficherDatesFermees)
            } else {
              if (!await contexte.checkDonneesModifiees())
                return

              contexte.propositionSelectionnee = filtreDateActivation.selection
              contexte.chargerFamilles(contexte.fournisseurCourantId, filtreDateActivation.selection?.valeur)
              contexte.filtres.statuts.initialiser(contexte, null) 													  

              // Gestion des accès de l'export de trame
              contexte.actions.exporterTrame.disabled = false
              if (filtreDateActivation.selection?.ouvertePourSaisie) {
                contexte.actions.importerPropositions.disabled = false
                contexte.actions.importerFT.disabled = false
                contexte.actions.envoyerPropositions.disabled = false
              } else {
                contexte.actions.importerPropositions.disabled = true
                contexte.actions.importerFT.disabled = true
                contexte.actions.envoyerPropositions.disabled = true
              }
            }
          }
        },
        afficherHistoriquePropositions: {
          label: "Afficher historique",
          champ: "checkbox",
          selection: false,
          filtrer() {}
        },
        familles: {
          label: "Familles",
          champ: "select",
          width: "235px",
          options: [],
          selection: null,
          initialiser(contexte, options) {
            let filtreFamilles = contexte.filtres.familles

            filtreFamilles.options = options
            // Sélection automatique de la 1ère famille si la liste n'est pas vide (elle correspond à la seule famille disponible s'il n'y en a qu'une ou à "Toutes familles" s'il y en a plusieurs)
            filtreFamilles.selection = filtreFamilles.options?.length ? filtreFamilles.options[0] : null

            contexte.filtres.statuts.initialiser(contexte, [])
            filtreFamilles.filtrer(contexte)
          },
          async filtrer(contexte) {
            let filtreFamilles = contexte.filtres.familles

            // Gestion des données modifiées pour permettre l'enregistrement avant de changer de famille et perdre la saisie de l'utilisateur
            if (!await contexte.checkDonneesModifiees()) {
              filtreFamilles.selection = contexte.familleSelectionnee
              return
            }
            
            contexte.messageImportFicheTechnique = ""
            contexte.familleSelectionnee = filtreFamilles.selection
            contexte.actions.exporterTrame.label = "Exporter " + (filtreFamilles.selection?.texte ?? "")
            contexte.actions.exporterTrame.tooltip = "Exporter la trame de cotation pour " + filtreFamilles.selection?.texte
            contexte.actions.envoyerPropositions.label = "Envoyer les propositions " + (filtreFamilles.selection?.texte ?? "")
            contexte.actions.envoyerPropositions.tooltip = "Envoyer les propositions au service Achats pour " + filtreFamilles.selection?.texte
            
            let propositionId = contexte.filtres.dateActivation.selection?.valeur
            let familleId = filtreFamilles.selection?.valeur

            if (propositionId && familleId) {
              // Chargement des propositions de la famille
              contexte.chargerPropositions(contexte.fournisseurCourantId, propositionId, familleId)
            } else {
              // Gestion du choix "Toutes familles"
              contexte.filtres.statuts.initialiser(contexte, null)
              contexte.tableauPropositions = null
              contexte.donneesInitiales = null
            }

            // Gestion des accès des actions qui nécessitent une famille
            if (!contexte.filtres.dateActivation.selection?.ouvertePourSaisie || filtreFamilles.selection?.ouvertePourSaisie === false) {
              // 
              contexte.actions.importerPropositions.disabled = true
              contexte.actions.importerFT.disabled = true
              contexte.actions.envoyerPropositions.disabled = true
            } else {
              // Cas de la sélection d'une famille spécifique
              contexte.actions.importerPropositions.disabled = false
              contexte.actions.importerFT.disabled = false
              contexte.actions.envoyerPropositions.disabled = false
            }
          }
        },
        statuts: {
          label: "Statuts de propositions",
          champ: "select",
          width: "330px",
          options: [],
          selection: null,
          initialiser(contexte, options) {
            let filtreStatuts = contexte.filtres.statuts
            filtreStatuts.options = options
            // On sélectionne par défaut le 1er statut avec propositions modifiables, et s'il n'y en a pas, on sélectionne le 1er statut disponible
            filtreStatuts.selection = options?.filter(o => o.modifiable)?.length ? options.filter(o => o.modifiable)[0] : options?.length ? options[0] : null
          },
          filtrer(contexte, produit) {
            let filtreStatuts = contexte.filtres.statuts
            if (!filtreStatuts.selection) {
              // Aucun statut n'est sélectionné, on n'affiche aucune proposition
              return false
            }
            if (filtreStatuts.selection.valeur === 0) {
              // Cas du filtre sur les propositions en erreur de conformité
              return !contexte.propositionEstConforme(contexte.propositionFournisseur(produit)?.propositionEnCours) || contexte.propositionEstWarning(contexte.propositionFournisseur(produit))
            }
            if (!Nombre(filtreStatuts.selection.valeur)) {
              // Cas du filtre sur une erreur spécifique
              return contexte.propositionFournisseur(produit)?.retourAgap.includes(filtreStatuts.selection.valeur) || contexte.propositionFournisseur(produit)?.warningAgap.includes(filtreStatuts.selection.valeur)
            }
            return contexte.propositionFournisseur(produit)?.propositionEnCours?.statutId === filtreStatuts.selection.valeur
          }
        },
        designationsProduit: {
          label: "Recherche produits",
          champ: "input",
          type: "text",
          width: "240px",
          selection: "",
          class: "search",
          filtrer(contexte, produit) {
            let propositionFournisseur = contexte.propositionFournisseur(produit)
            let designationsProduit = propositionFournisseur ? produit.codeProduitAgap + " " + produit.nomProduitAgap + " " + propositionFournisseur.prixActif?.designation + " " + propositionFournisseur.prixActif?.reference + " " + propositionFournisseur.prixActif?.marque + " " +
              propositionFournisseur.propositionEnCours?.designation + " " + propositionFournisseur.propositionEnCours?.reference + " " + propositionFournisseur.propositionEnCours?.marque : ""
            return rechercherMultiTexte(designationsProduit, contexte.filtres.designationsProduit.selection)
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.filtres.barreGauche = [ contexte.filtres.dateActivation, contexte.filtres.familles, contexte.filtres.statuts ]
          contexte.filtres.barreDroite = [ contexte.filtres.designationsProduit, contexte.filtres.afficherHistoriquePropositions ]
          contexte.filtres.barreGauche.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })
        }
      },
      actions: {
        // Boutons à afficher dans la barre d'action
        // Exemple :
        /*
        nomAction: {
          label: Label affiché sur le bouton (string),
          disabled: Etat actif/inactif par défaut du bouton (bool),
          class: Classe CSS (btn-primary, btn-success, btn-warning ou btn-danger) à appliquer sur le bouton (par défaut : "btn-primary" est appliqué => il s'agit du bouton bleu Agap'pro) (string)
          methode(contexte) {
            // => contexte équivaut à this (mais this ne fonctionne pas car l'initialisation de data n'est pas encore terminée, donc data n'est pas encore rattaché à this)
            traitements à exécuter lors du clic sur le bouton
            les traitements définis dans cette page doivent être appelés sous la forme : contexte.traitement()

            Remarque : pour que le système fonctionne, il faut déclarer la méthode ci-dessous dans la section "methods" de cette page
            executerAction(methode) {
              methode(this)
            }

          }
        }
        */
        exporterTrame: {
          label: "Exporter",
          disabled: true,
          async methode(contexte) {
            if (contexte.donneesModifiees) {
              let response = await contexte.alerteConfirmation("Modifications en cours", "Attention, vos modifications en cours ne seront pas prises en compte dans l'export, nous préconisons d'enregistrer vos modifications avant l'export. Souhaitez-vous continuer ?")
              if (!response)
                return
            }
            // Appeler l'API d'export des trames
            contexte.exporterTrameCotation()
          }
        },
        importerPropositions: {
          label: "Importer Propositions",
          tooltip: 'Importer une ou plusieurs trames de cotations',
          disabled: true,
          async methode(contexte) {
            if (contexte.donneesModifiees) {
              let response = await contexte.alerteConfirmation("Modifications en cours", "Attention, vos modifications en cours seront perdues. Souhaitez-vous continuer ?")
              if (!response)
                return
            }
            contexte.selectedFile=null
            contexte.formData=null
            contexte.$refs.importCotations.click()
            // Appeler l'API d'import des propositions fournisseurs
          }
        },
        importerFT: {
          label: "Importer FT (100 Mo maximum)",
          tooltip: 'Importer un PDF ou un ZIP de fiches techniques',
          disabled: true,
          methode(contexte) {
            contexte.selectedFile=null
            contexte.formData=null
            contexte.propoArticleFournId = null
            contexte.key++
            contexte.$refs.importFicheTechnique.click()
            // Appeler l'API d'import des fiches techniques fournisseurs
          }
        },
        envoyerPropositions: {
          label: "Envoyer les propositions",
          disabled: true,
          methode(contexte) {
            contexte.envoyerPropositions()
          }
        },
        annuler: {
          label: "Annuler",
          disabled: true,
          class: "btn-danger",
          methode(contexte) {
            contexte.tableauPropositions = _.cloneDeep(contexte.donneesInitiales)
            contexte.listeProduitsModifies = []
          }
        },
        enregistrer: {
          label: "Enregistrer",
          disabled: true,
          class: "btn-success",
          methode(contexte) {
            contexte.enregistrerDonneesModifiees()
          }
        },
        // Appeler "this.actions.initialiser(this)"" dans mounted() => génère les tableaux d'actions utilisés pour afficher les boutons à gauche et à droite de la barre d'actions
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.actions.barreGauche = [ contexte.actions.exporterTrame, contexte.actions.importerPropositions, contexte.actions.importerFT, contexte.actions.envoyerPropositions ]
          contexte.actions.barreDroite = [ contexte.actions.annuler, contexte.actions.enregistrer ]
        }
      }
    }
  },
  computed: {
    // Variable utilisée pour afficher les messages dans la barre d'informations
    informations() {
      let messagesGauche = []
      if (this.messageImportFicheTechnique) {
        messagesGauche.push(creerMessageBarreInformation((!this.importFicheTechniqueTermine ? "fas fa-spinner fa-spin" : this.listeErreurFicheTechnique?.length ? "fas fa-exclamation-triangle text-warning" : "fas fa-check-circle text-success"), this.messageImportFicheTechnique, null))
        if (this.importFicheTechniqueTermine && this.listeErreurFicheTechnique?.length) {
          messagesGauche.push(creerMessageBarreInformation(null, "Voir erreurs", "btn btn-primary btn-sm", () => { this.afficherDetailImportFicheTechnique() }))
        }
      }

      let messagesCentre = []
      if (this.periodeFamilleEstModifiable()) {
        messagesCentre.push(creerMessageBarreInformation(null, "Saisie des propositions ouverte jusqu'au ", null))
        messagesCentre.push(creerMessageBarreInformation(null, this.formatHumanDateAvecSlashes(this.tableauPropositions?.dateFinAcces), "bold600"))
      } else if (!this.toutesPropositionsModifiablesParAgap(this.tableauPropositions)) {
        messagesCentre.push(creerMessageBarreInformation("fas fa-exclamation-triangle text-error", "La mise à jour tarifaire n'est plus possible pour cette famille à cette date. Merci de contacter le service achats d'Agap'pro.", "text-error bold600"))
      }

      let messagesDroite = []
      if (this.afficherTableauPropositions && this.periodeFamilleEstModifiable()) {
        messagesDroite.push(creerMessageBarreInformation(null, "La variation globale des tarifs de cette famille est de ", null))
        messagesDroite.push(creerMessageBarreInformation(null, this.afficherPCBetUC(this.totaleVariations(),'') + " €", "bold600 " + (this.totaleVariations() < 0 ? "text-success" : this.totaleVariations() > 0 ? "text-error" : "")))
        messagesDroite.push(creerMessageBarreInformation(null, " soit ", null))
        messagesDroite.push(creerMessageBarreInformation(null, this.afficherPourcentageVariationTotale(), "bold600 " + (this.totaleVariations() < 0 ? "text-success" : this.totaleVariations() > 0 ? "text-error" : "")))
      }

      return {
        messagesGauche: messagesGauche,
        messagesCentre: messagesCentre,
        messagesDroite: messagesDroite
      }
    },
    afficherTableauPropositions() {
      return this.tableauPropositions ?? false
    },
    donneesModifiees() {
      return this.listeProduitsModifies?.length || (this.donneesInitiales && this.tableauPropositions && !_.isEqual(JSON.stringify(this.donneesInitiales), JSON.stringify(this.tableauPropositions)))
    },
    dateIdSelectionnee() {
      return this.filtres.dateActivation.selection ? this.filtres.dateActivation.selection.valeur : 0
    },
    familleIdSelectionnee() {
      return this.filtres.familles.selection?.valeur ?? 0
    },
    propoArticleSelectionnee() {
      this.key
      return this.propoArticleFournId
    },
    contenuDuFichier() {
      return this.formData ?? new FormData()
    },
    nomDuFichier() {
      return this.selectedFile?.name ?? ""
    },
    listeLiensFicheTechnique() {
      this.key
      return this.tableauPropositions ? this.listeProduits(this.tableauPropositions).map(m => {
        return this.propositionFournisseur(m)
      }).filter(p => p.propositionEnCours?.lienFicheTechnique) : null;
    },
    listeErreurFicheTechnique() {
      this.key
      return this.listeLiensFicheTechnique?.filter(p => !p.propositionEnCours?.ficheTechniqueAzureOk);
    },
    blobFicheTechnique() {
      return this.blobFt ?? new Blob()
    },
    afficherErreurs() {
      return this.tableauPropositions.afficherErreurs
    },
    totalPrixParCategorie() {
      //retourne le total des prix Actifs par categorie et le total des prix des propositions, afin de calculer la variation par categorie
      
      let totalCategorie = this.tableauPropositions ? this.tableauPropositions.categories?.map(c => {
        let totalPropo = 0
        let totalActif = 0
        let pf = c.sousCategories?.map(s => s.produits).reduce((a, b) => a.concat(b), []).map(p => p.propositionsFournisseurs).reduce((a, b) => a.concat(b), []) || []
        
        pf.forEach(p => {
          if (!this.propositionEstVide(p.propositionEnCours) && p.propositionEnCours.prixUnitaireHT && p.prixActif) {
            totalPropo += p.propositionEnCours?.prixUnitaireHT
            totalActif += p.prixActif?.prixUnitaireHT ?? 0
          }
        })
        
        let ret = {
          categorieId: c.categorieId,
          totalPropo: totalPropo,
          totalActif: totalActif
        }
        return ret;
      }) : null
      return totalCategorie
    }
  },
  watch: {
    afficherTableauPropositions() {
      //this.actions.disableBoutonsPropositions(this.actions, !this.afficherTableauPropositions || !this.contientPropositionsModifiables(this.tableauPropositions))
    },
    donneesModifiees() {
      if (this.donneesModifiees) {
        //this.actions.envoyerPropositions.disabled = true
        this.actions.enregistrer.disabled = false
        this.actions.annuler.disabled = false
      } else {
        this.actions.enregistrer.disabled = true
        this.actions.annuler.disabled = true
      }
    }
  },
  async mounted() {
    this.filtres.initialiser(this)
    this.actions.initialiser(this)

    await this.chargerDatesPropositions(this.fournisseurCourantId, false)
    this.chargerStatuts()
    this.messageImportFicheTechnique = ""
											
  },
  methods: {
    /* *************************** METHODE TECHNIQUE ******************************** */
    appliquerFiltre(filtrer) {
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },
    executerAction(methode) {
      // Nécessaire pour faire fonctionner les actions de la barre d'actions'
      methode(this)
    },

    /* **************************** GESTION FICHES TECHNIQUES *********************** */
    async importerUneFicheTechnique(propoArticleId) {
      //Permet d'importer une fiche technique sur une proposition article (id en parametre)
      //ce n'est possible que si les propositions ont été enregistrées afin d'avoir les Id de chaque PropoArticleFournisseur
      //fournisseurId, propositionId, propoArticleId, fichier
      //let ret = await this.importerUneFicheTechnique()
      this.selectedFile=null
      this.formData=null
      this.propoArticleFournId = propoArticleId
      this.key++
      this.$refs.importFicheTechnique.click()
    },
    afficherDetailImportFicheTechnique() {
      //Ouvre la modal pour visualiser le detail du chargement des fiches techniques
      this.$refs.modalAfficheDetailFT.show()
    },
    async afficherMessageFicheTechnique(message, etatTermine) {
      //Permet d'indiquer sur la page l'etat de chargement des fiches techniques, appeler depuis le chargement des fichiers de cotation
      await this.chargerErreursDansPropositions()
      if (message) {
        this.messageImportFicheTechnique = message
        this.importFicheTechniqueTermine = etatTermine
      } else {
        this.messageImportFicheTechnique = ""
      }

      this.chargerOptionsStatuts()
      this.key++
    },
    chargerFichesTechniques(event) {
      //Récupération de la ou les fiches techniques depuis le bouton import FT
      //on récupère le fichier à traiter
      if (event.target.files.length != 0) {
        this.formData = new FormData();
        this.selectedFile = event.target.files[0];
        
        if (this.selectedFile.size > (1024 * 1024 * 100)) {
          // Fichier de 100 Mo maximum
          this.alerteErreur("Le fichier que vous tentez d'importer est trop volumineux (" + arrondiMercuriale(this.selectedFile.size / 1024 / 1024) + " Mo). Taille maximale : 100 Mo.")
          return
        }
        
        this.formData.append('fichier', this.selectedFile);
        this.$refs.modalImportFichesTechniques.show()
        this.$refs.importFicheTechnique.value = null
      }
    },
    async ouvrirFicheTechnique(propositionArticleFournisseurId) {
      //Ouvre la FT stockée sur Azure, via le referentiel Mercuriale
      let ret = await obtenirFicheTechnique(this.fournisseurCourantId, propositionArticleFournisseurId)
      let blob = new Blob([ret.data], {type: 'application/pdf'})
      let blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl)
    },
    async chargerErreursDansPropositions() {
      //Met à jour le champ d'erreur dans les propositions
      let propositionId = this.filtres.dateActivation.selection?.valeur
      let familleId = this.filtres.familles.selection?.valeur

      
      if (propositionId && familleId) {
        let response = await obtenirPropositions(this.fournisseurCourantId, propositionId, familleId)
        let produitsAJour = this.listeProduits(response.data)
        for (let iCategorie in this.tableauPropositions.categories) {
          let categorie = this.tableauPropositions.categories[iCategorie]
          for (let iSousCategorie in categorie.sousCategories) {
            let sousCategorie = categorie.sousCategories[iSousCategorie]
            for (let iProduit in sousCategorie.produits) {
              let produit = sousCategorie.produits[iProduit]
              let proposition = this.propositionFournisseur(produit)
              let produitAJour = produitsAJour.filter(x => x.codeProduitAgap == produit.codeProduitAgap).shift()
              let propositionAJour = produitAJour ? this.propositionFournisseur(produitAJour) : null
              if (proposition && propositionAJour) {
                proposition.retourAgap = propositionAJour.retourAgap
                proposition.propositionEnCours.ficheTechniqueAzureOk = propositionAJour.propositionEnCours.ficheTechniqueAzureOk
                proposition.propositionEnCours.erreurAzureFicheTechnique = propositionAJour.propositionEnCours.erreurAzureFicheTechnique
                let produitInitial = this.donneesInitiales.categories[iCategorie]?.sousCategories[iSousCategorie]?.produits[iProduit]
                let propositionInitiale = produitInitial ? this.propositionFournisseur(produitInitial) : null
                if (propositionInitiale) {
                  propositionInitiale.retourAgap = propositionAJour.retourAgap
                  propositionInitiale.propositionEnCours.ficheTechniqueAzureOk = propositionAJour.propositionEnCours.ficheTechniqueAzureOk
                  propositionInitiale.propositionEnCours.erreurAzureFicheTechnique = propositionAJour.propositionEnCours.erreurAzureFicheTechnique
                }
              }
            }
          }
        }
      }
    },

    /* ******************************* GESTION FICHIERS COTATIONS *************************** */
	
    chargerFichierCotation(event) {
      //Traitement du ou des fichiers de cotation via le bouton importer propositions
      //on récupère le fichier à traiter
      if (event.target.files.length != 0) {
        this.formData = new FormData();
        this.selectedFile = event.target.files[0];

        if (this.selectedFile.size > (1024 * 1024 * 100)) {
          // Fichier de 100 Mo maximum
          this.alerteErreur("Le fichier que vous tentez d'importer est trop volumineux (" + arrondiMercuriale(this.selectedFile.size / 1024 / 1024) + " Mo). Taille maximale : 100 Mo.")
          return
        }

        this.formData.append('fichierCotation', this.selectedFile);
        this.$refs.modalImportFichierCotation.show()
        this.$refs.importCotations.value = null
      }
    },
    async exporterTrameCotation() {
      //Téléchargement du ou des fichiers Excels de demande de cotation sur la ou les familles sélectionnées (Une ou toutes les familles)
      this.$store.commit('uxHelpers/showSpinner')

      let listeFamille = []
      if (this.filtres.familles.selection?.valeur) { //Si on a sélectionné une famille on exporte uniquement celle-ci, sinon on exporte toutes les familles ouvertes sur la proposition
        listeFamille.push(this.filtres.familles.selection.valeur)
      } else {
        listeFamille = this.filtres.familles.options.filter(o => o.valeur).map(o => {
          return o.valeur
        })
      }

      await obtenirTrameCotation(this.fournisseurCourantId, this.filtres.dateActivation.selection.valeur, listeFamille)
        .then(resp => {
          this.$store.commit('uxHelpers/hideSpinner')
          saveAs(new File([resp.data], "DemandeCotation.zip"))
          return
        })

        this.$store.commit('uxHelpers/hideSpinner')
    },

    /* ******************* GESTION DES FILTRES *************************************** */
    
    async chargerStatuts() {
      //Récupère l'ensemble des statuts possible
      let response = await obtenirStatutsPropositions()
      this.statuts = response.data.filter(s => s.visible)
    },
    chargerOptionsStatuts() {
      //Formate la liste du filtre Statuts de Propositions
      let produits = this.listeProduits(this.tableauPropositions)
      let statutsProposIds = produits.map(p => this.propositionFournisseur(p)?.propositionEnCours?.statutId)
      let statutsIds = [...new Set(statutsProposIds)]
      let optionsStatuts = this.statuts.filter(s => statutsIds.includes(s.id)).map(s => {
        return {
          valeur: s.id,
          texte: s.nom + " (" + statutsProposIds.filter(id => id === s.id).length + ")",
          style: "color:" + this.couleurHexCss(s.codeCouleur),
          modifiable: !s.pourAgap
        }
      })
      if (this.afficherErreurs) {
        let proposEnErreur = produits.filter(p => this.estModifiable(this.propositionFournisseur(p)?.propositionEnCours) && (!this.propositionEstConforme(this.propositionFournisseur(p)?.propositionEnCours) || this.propositionEstWarning(this.propositionFournisseur(p))))

        if (proposEnErreur.length) {
          let erreurs = proposEnErreur.filter(o => this.propositionFournisseur(o)?.retourAgap != "").map(p => this.propositionFournisseur(p)?.retourAgap)
          let warning = proposEnErreur.filter(o => this.propositionFournisseur(o)?.warningAgap != "").map(p => this.propositionFournisseur(p)?.warningAgap)

          let erreursDistinct = [...new Set(erreurs)]
          let warningDistinct = [...new Set(warning)]

          let messagesErreurs = []
          erreursDistinct.forEach(e => {
            e.split(" - ").forEach(m => {
              messagesErreurs.push(m)
            })
          })

          let messagesWarning = []
          warningDistinct.forEach(e => {
            e.split(" - ").forEach(m => {
              messagesWarning.push(m)
            })
          })

          let messagesErreursDistinct = [...new Set(messagesErreurs)]
          let messagesWarningDistinct = [...new Set(messagesWarning)]

          messagesErreursDistinct.forEach(e => {
            optionsStatuts.push({
              valeur: e,
              texte: e + " (" + proposEnErreur.filter(p => this.propositionFournisseur(p)?.retourAgap.includes(e)).length + ")",
              style: "color:" + this.couleurHexCss(couleurs.rouge) //"color: red"
            })
          })

          messagesWarningDistinct.forEach(e => {
            optionsStatuts.push({
              valeur: e,
              texte: e + " (" + proposEnErreur.filter(p => this.propositionFournisseur(p)?.warningAgap.includes(e)).length + ")",
              style: "color:" + this.couleurHexCss(couleurs.jaune)
            })
          })

          if (proposEnErreur.filter(o => this.propositionFournisseur(o)?.retourAgap != "").length > 0) {
            optionsStatuts.push({
              valeur: 0,
              texte: "Toutes erreurs (" + proposEnErreur.filter(o => this.propositionFournisseur(o)?.retourAgap != "" || this.propositionFournisseur(o)?.warningAgap != "").length + ")",
              style: "color:" + this.couleurHexCss(couleurs.rouge)
            })
          }
        }

      }
      this.filtres.statuts.initialiser(this, optionsStatuts)
    },
    async chargerDatesPropositions(fournisseurId, avecDateFermees) {
      //Récupère les dates d'applications pour lesquelles on demande des cotations
      this.afficherDatesFermees = avecDateFermees
      let response = await obtenirDatesPropositionsOuvertes(fournisseurId, !avecDateFermees)
      let dates = response.data.map(d => {
        return {
          valeur: d.propositionId,
          texte: (!d.ouvertePourSaisie ? "[Fermée] " : "") + this.formatHumanDateAvecSlashes(d.dateActivation) + (d.appelOffre ? !d.ouvertePourSaisie ? " (AO)" : " (Appel d'offre)" : ""),
          class: (d.appelOffre ? "text-danger " : "") + (!d.ouvertePourSaisie ? "inactif" : ""),
          ouvertePourSaisie: d.ouvertePourSaisie
        }
      })
      if (!avecDateFermees) {
        dates.push({
          valeur: -1,
          texte: "",
          icone: "fas fa-caret-down",
          class: "flex-center-space-between"
        })
      } else {
        dates.push({
          valeur: -1,
          texte: "",
          icone: "fas fa-caret-up",
          class: "flex-center-space-between"
        })
      }
      this.filtres.dateActivation.initialiser(this, dates)
    },
    async chargerFamilles(fournisseurId, propositionId) {
      //Récupère la liste des familles possible sur la date d'application sélectionnée
      let response = await obtenirFamillesProposition(fournisseurId, propositionId)
      let familles = response.data.map(f => {
        return {
          valeur: f.familleId,
          texte: (!f.ouvertePourSaisie ? "[Fermée] " : "") + f.nom,
          class: (!f.ouvertePourSaisie ? "inactif" : ""),
          ouvertePourSaisie: f.ouvertePourSaisie
        }
      })
      if (familles?.length > 1) {
        familles.unshift({ valeur: null, texte: "Toutes familles", class: "", ouvertePourSaisie: null })
      }
      this.filtres.familles.initialiser(this, familles)
    },
    filtrer(produit) {
      //Filtre les propositions en fonction de la barre de filtres
      return this.filtres.statuts.filtrer(this, produit) && this.filtres.designationsProduit.filtrer(this, produit)
    },

    /* ************************ GESTION DES PROPOSITIONS *************************************** */
    totalCategorie(categorie) {
      return this.totalPrixParCategorie.filter(c => c.categorieId === categorie.categorieId).shift()
    },
    totaleVariations() {
      return this.totalPrixParCategorie?.reduce((acc, obj) => {return acc + obj?.totalPropo}, 0) - this.totalPrixParCategorie?.reduce((acc, obj) => {return acc + obj?.totalActif}, 0)
    },
    afficherPourcentageVariationTotale() {
      return this.afficherPourcentageFluctuation(this.totalPrixParCategorie?.reduce((acc, obj) => {return acc + obj?.totalPropo}, 0), this.totalPrixParCategorie?.reduce((acc, obj) => {return acc + obj?.totalActif}, 0))  
    },
    pourcentageVariationTotale() {
      return this.pourcentageFluctuation(this.totalPrixParCategorie?.reduce((acc, obj) => {return acc + obj?.totalPropo}, 0), this.totalPrixParCategorie?.reduce((acc, obj) => {return acc + obj?.totalActif}, 0))
    },
    async chargerPropositionsApresImport() {
      //Recharge la liste des propositions apres l'import d'un fichier de cotation ou de fiches techniques
      let propositionId = this.filtres.dateActivation.selection?.valeur
      let familleId = this.filtres.familles.selection?.valeur

      if (propositionId && familleId && !this.propoArticleSelectionnee) {
        await this.chargerPropositions(this.fournisseurCourantId, propositionId, familleId)  
      } else {
        await this.chargerErreursDansPropositions()
        this.chargerOptionsStatuts()
      }
    },
    async chargerPropositions(fournisseurId, propositionId, familleId, avecSpinner=true) {
      //Récupère les propositions articles fournisseurs sur la date et la famille sélectionnée
      if (avecSpinner)
        this.$store.commit('uxHelpers/showSpinner')

      let response = await obtenirPropositions(fournisseurId, propositionId, familleId)
      this.tableauPropositions = response.data
      this.listeProduitsModifies = []
      this.chargerOptionsStatuts()
      this.donneesInitiales = _.cloneDeep(this.tableauPropositions)
      
      if (avecSpinner)
        this.$store.commit('uxHelpers/hideSpinner')
    },
    
    async enregistrerDonneesModifiees(avecSpinner=true) {
      //Enregistre les propositions articles fournisseur
      let propositionId = this.propositionSelectionnee?.valeur
      let familleId = this.familleSelectionnee?.valeur

      if (propositionId && familleId) {
        if (avecSpinner)
          this.$store.commit('uxHelpers/showSpinner')

        let ret = await enregistrerPropositions(this.fournisseurCourantId, propositionId, familleId, this.tableauPropositions)
        if (!ret.data.familleOk) {
          this.alerteWarning("Des erreurs sont apparues lors de l'enregistrement de vos propositions :<br/>" + ret.data.messagesErreurs)
        } else
          this.alerteSucces(ret.data.nombreProposTraitees + " " + plurielUC(ret.data.nombreProposTraitees, "proposition") + " " + plurielUC(ret.data.nombreProposTraitees, " enregistrée") + " !")

        if (avecSpinner)
          this.$store.commit('uxHelpers/hideSpinner')

        this.chargerPropositions(this.fournisseurCourantId, propositionId, familleId, avecSpinner)
      }
    },
    async envoyerPropositions() {
      //Change le statut des propositions pour les envoyer au service Achats
      let propositionId = this.filtres.dateActivation.selection?.valeur
      let familleId = this.filtres.familles.selection?.valeur
      let objParam = {}

      if (propositionId) {
        if (familleId) {
          if (this.donneesModifiees) {
            // Envoi interdit en cas de modifications en cours
            this.alerteErreur("Des propositions sont en cours de modification. Merci d'enregistrer vos modifications avant d'envoyer vos propositions en validation par le service Achats Agap'pro.")
            return
          }

          if (!this.contientPropositionsModifiables(this.tableauPropositions)) {
            // Aucune proposition à envoyer
            this.alerteErreur("Aucune proposition à envoyer.")
            return
          }

          objParam = {
            propositionId: propositionId,
            listeFamillesId: [familleId],
            userName: ''
          }
				
        }
        else {
          //On va envoyer toutes les familles possible
          objParam = {
            propositionId: propositionId,
            listeFamillesId: this.filtres.familles.options.filter(f => f.valeur).map(o => o.valeur),
            userName: ''
          }
        }
        
        if (familleId && !this.contientPropositionsEnregistrees(this.tableauPropositions)) {
          let response = await this.alerteConfirmation("Propositions non modifiées", "Nous n'avons détecté aucune modification dans vos propositions. Etes-vous sûr de vouloir les envoyer ?", "Oui", "Non", "warning", couleurs.erreur)
          if (!response)
            return  
          else {
            this.$store.commit('uxHelpers/showSpinner')
            await this.enregistrerDonneesModifiees(false)  
          }
        } else {
          let response = await this.alerteConfirmation("Envoyer les propositions", "Vos propositions vont être transmises au service Achats pour analyse. Souhaitez-vous continuer ?")
          if (!response)
            return
        }

        
        this.$store.commit('uxHelpers/showSpinner')

        await envoyerPropositions(this.fournisseurCourantId, objParam)
        this.alerteSucces("Propositions envoyées !")

        this.$store.commit('uxHelpers/hideSpinner')

        if (familleId)
          this.chargerPropositions(this.fournisseurCourantId, propositionId, familleId)
      }
    },
    viderProposition(proposition) {
      //vide la proposition article
      proposition.retourAgap = '' // On vide le message de retour .... il reviendra à l'enregistrement s'il y a d'autres erreurs
      this.alimenterProposition(proposition?.propositionEnCours, null)
    },
    alimenterProposition(proposition, donnees) {
      //permet d'alimeneter les données de la proposition, soit pour la vider, ou pour récupérer le tarif actif
      if (!proposition)
        return

      // Le prix est vidé systématiquement, il n'est jamais repris des données à copier
      proposition.prixUnitaireHT = null

      // Le reste des données est copié normalement, ou vidé si aucune donnée à copier n'est fournie
      proposition.designation = donnees?.designation ?? null
      proposition.reference = donnees?.reference ?? null
      proposition.marque = donnees?.marque ?? null
      proposition.pcb = donnees?.pcb ?? null
      proposition.uniteFacturation = donnees?.uniteFacturation ?? null
      proposition.uniteCommandeEDI = donnees?.uniteCommandeEDI ?? null
      proposition.lienFicheTechnique = donnees?.lienFicheTechnique ?? null
      proposition.codeEANArticle = donnees?.codeEANArticle ?? null
      proposition.codeEANCarton = donnees?.codeEANCarton ?? null
      proposition.commentaireFournisseur = donnees?.commentaireFournisseur ?? null
    },
    propositionEstVide(proposition) {
      if (!proposition)
        return true
      
      return !proposition.designation &&
             !proposition.reference &&
             !proposition.marque &&
             !proposition.pcb &&
             !proposition.prixUnitaireHT &&
             !proposition.uniteFacturation &&
             !proposition.uniteCommandeEDI //&&
            //  !proposition.lienFicheTechnique &&
            //  !proposition.codeEANArticle &&
            //  !proposition.codeEANCarton
    },
    propositionEstComplete(proposition) {
      if (!proposition)
        return true

      if (proposition.id)
        return proposition.estConforme
      else
        return proposition.designation && 
               proposition.pcb && 
               proposition.prixUnitaireHT && 
               proposition.uniteFacturation && 
               proposition.ficheTechniqueAzureOk
    },
    propositionEstWarning(propoFournisseur) {
      if (!propoFournisseur)
        return false

      if (propoFournisseur.propositionEnCours?.EnLectureSeule ?? false)
        return false

      return propoFournisseur.warningConformite && 
             propoFournisseur.warningConformite.length > 0
    },
    propositionEstConforme(proposition) {
      return this.propositionEstComplete(proposition) || this.propositionEstVide(proposition)
    },
    afficherPCBetUC(pcb, uc) {
      let arrondi = arrondiMercuriale(pcb)
      if (!arrondi)
        return ""
      return arrondi + " " + uc
    },
    afficherPrixConverti(prix, pcb, uc, unite) {
      if (!Nombre(prix) || !Nombre(pcb))
        return ""
      return arrondiPrixPCB(pcb, pcb, prix) + " / " + (unite ? unite : pcb + " " + uc)
    },
    pourcentageFluctuation(prix1, prix2) {
      return arrondiPourcentageDifference(prix1, prix2)
    },
    afficherPourcentageFluctuation(prix1, prix2) {
      let prc = arrondiPourcentageDifference(prix1, prix2)
      if (prc > 0)
        return "+" + prc + "%"
      return prc + "%"
    },
    estVisible(produit) {
      let propositionFournisseur = this.propositionFournisseur(produit)
      if (!propositionFournisseur)
        return false

      // Toute proposition en cours de modification est masquée lorsque la date d'activation des propositions est dépassée
      if (this.formatDateAvecTirets(new Date()) >= this.formatDateAvecTirets(this.filtres.dateActivation.selection) && !(propositionFournisseur.propositionEnCours?.enLectureSeule ?? true))
        return false

      return true
    },
    estModifiableParAgap(proposition) {
      if (!proposition)
        return false
      return proposition.enLectureSeule
    },
    toutesPropositionsModifiablesParAgap(tableauPropositions) {
      return !this.listeProduits(tableauPropositions).some(p => !this.estModifiableParAgap(this.propositionFournisseur(p)?.propositionEnCours))
    },
    contientPropositionsModifiables(tableauPropositions) {
      return this.listeProduits(tableauPropositions).some(p => this.estModifiable(this.propositionFournisseur(p)?.propositionEnCours))
    },
    contientPropositionsEnregistrees(tableauPropositions) {
      //Permet de savoir si la vue affichée sur l'écran correspond à des propositions enregistrée en base de données (toutes les propos)
      return this.listeProduits(tableauPropositions).every(p => this.estEnregistree(this.propositionFournisseur(p)?.propositionEnCours))
    },
    periodeFamilleEstModifiable() {
      return this.filtres.dateActivation.selection?.ouvertePourSaisie && this.filtres.familles.selection?.ouvertePourSaisie
    },
    estModifiable(proposition) {
      if (!proposition)
        return false
      if (!this.periodeFamilleEstModifiable())
        return false
      return !proposition.enLectureSeule
    },
    estEnregistree(proposition) {
      if (!proposition)
        return false

      console.log("Proposition enregistree : " + (proposition.id ? "true" : "false"))
      return proposition.id ? true : false
    },
    estModifie(produit) {
      if (!produit)
        return false
      return this.listeProduitsModifies.some(p => p.produitAgapId === produit.produitAgapId)
    },
    contientModification(produit) {
      if (!produit)
        return false
      let produitInitial = this.listeProduits(this.donneesInitiales).find(p => p.produitAgapId === produit.produitAgapId)
      if (!produitInitial)
        return true
      return !_.isEqual(JSON.stringify(produitInitial), JSON.stringify(produit))
    },
    majlisteProduitsModifies() {
      let produit = this.listeProduits(this.tableauPropositions).find(p => p.produitAgapId === this.produitSelectionneId)
      if (produit) {
        let produitModifie = this.contientModification(produit)
        let produitDansListeModif = this.estModifie(produit)
        if (produitModifie && !produitDansListeModif) {
          this.listeProduitsModifies.push(produit)
        } else if (!produitModifie && produitDansListeModif) {
          this.listeProduitsModifies = this.listeProduitsModifies.filter(p => p.produitAgapId !== produit.produitAgapId)
        }
      }
    },
    listeProduits(tableauPropositions) {
      return tableauPropositions?.categories?.map(c => c.sousCategories).reduce((a, b) => a.concat(b), []).map(sc => sc.produits).reduce((a, b) => a.concat(b), []) || []
    },
    propositionFournisseur(produit) {
      return produit?.propositionsFournisseurs?.find(pf => pf.fournisseurId === this.fournisseurCourantId)
    },
    nbLignesAffichageProposition(produit) {
      let nbLignes = 0  // Ligne de proposition en cours
      if (this.estVisible(produit)) {
        nbLignes += this.propositionFournisseur(produit)?.retourAgap ? 2 : 1
      }
      if (this.filtres.afficherHistoriquePropositions.selection) {
        this.propositionFournisseur(produit)?.historiquePropositions?.forEach(p => {
          nbLignes += p.retourAgap ? 2 : 1
        })
      }
      return nbLignes
    },
    couleurHexCss(couleur, pourcentageOpacite) {
      if (!couleur)
        return ""
      return (couleur.charAt(0) != "#" ? "#" : "") + couleur + (convertirPourcentageToHex(pourcentageOpacite) ?? "")
    },
    champObligatoire(produit, valeur, obligatoire) {
      //On indique si le champ est obligatoire uniquement si la ligne est sélectionnée
      if (produit.produitAgapId === this.produitSelectionneId && obligatoire && !valeur && !this.propositionEstVide(this.propositionFournisseur(produit)?.propositionEnCours)) {
        return "fas fa-exclamation text-danger ml-1"
      } else {
        return "";
      }
    },
    styleBordure(bordureCss, produit, nbPxEpaisseur, pourcentageOpacite) {
      let propositionFournisseur = this.propositionFournisseur(produit)
      if (!propositionFournisseur?.propositionEnCours?.couleur || !Nombre(nbPxEpaisseur))
        return ""
      let style = bordureCss ? bordureCss + ":" : ""
      return style + "solid " + nbPxEpaisseur + "px " + this.couleurHexCss(propositionFournisseur.propositionEnCours.couleur, pourcentageOpacite)
    },

    /* ******************************* GESTION DU CURSEUR POUR NAVIGATION TABLEAU ************************** */
    positionProduit(produits, produitId) {
      return produits.map(p => p.produitAgapId).indexOf(produitId)
    },
    selectionnerProduit(produitId) {
      this.majlisteProduitsModifies()
      this.produitSelectionneId = produitId
    },
    deplacerCurseurTexte(produitId, col, e) {
      this.deplacerCurseur(produitId, col, deplacerCurseur(e, true))
    },
    deplacerCurseur(produitId, col, e) {
      let sensLigne = 0
      let sensColonne = 0
      if (e?.direction === "up") {
        sensLigne = -1
      } else if (e?.direction === "down") {
        sensLigne = 1
      } else if (e?.direction === "left") {
        sensColonne = -1
      } else if (e?.direction === "right") {
        sensColonne = 1
      }
      if (sensLigne != 0) {
        let produits = this.listeProduits(this.tableauPropositions).filter(p => this.filtrer(p) && this.estModifiable(this.propositionFournisseur(p)?.propositionEnCours))
        let indice = this.positionProduit(produits, produitId)
        if (indice < 0)
          return
        indice = calculerIndiceDeplacement(indice, sensLigne, produits.length)
        if (indice == null)
          return
        produitId = produits[indice]?.produitAgapId
        if (!produitId)
          return
      }
      if (sensColonne != 0) {
        // 6 colonnes de saisie
        col = calculerIndiceDeplacement(col, sensColonne, 8)
      }
      // Recherche et sélection du champ suivant selon la flèche du clavier
      let champ = document.getElementById("col" + (col) + produitId)
      if (champ) {
        this.selectionnerProduit(produitId)
        champ.focus()
      }
    }
    
  }
}
</script>
