import Vue from "vue"
import VueSweetalert2 from 'vue-sweetalert2'
import couleurs from '@/assets/css/couleurs.scss'
Vue.use(VueSweetalert2)

export const showAlerts = {
  methods: {
    async alerteSucces(message) {
      /*await this.$bvModal.msgBoxOk(message, {
        title: "Opération réussie",
        headerBgVariant: "success",
        okVariant: "success"
      })*/
      // Use sweetalert2
      await this.$swal({
        title: message,
        icon: "success",
        timer: 2000,
        // showCancelButton: false, // exemple gardé pour le cancel
        showConfirmButton: false
      })
    },
    logEtMontrerAlerte(message, exception) {
      this.alerteErreur(message, exception)
    },
    async alerteErreur(message, exception) {
      let consolemsg = message
      if (exception) {
        if (exception.erreurDejaAffichee)
          return
        consolemsg += "\n" + exception.stack
      }
      console.error(consolemsg)
      /*await this.$bvModal.msgBoxOk(message, {
        title: "Opération impossible",
        headerBgVariant: "danger",
        okVariant: "danger"
      })*/
      await this.$swal.fire({
        icon: "error",
        //  timer: 2500,
        title: "Opération impossible",
        html: message,
        showConfirmButton: true
      })
    },
    async alerteWarning(message) {
      await this.$swal.fire({
        icon: "warning",
        //  timer: 2500,
        title: "Attention",
        html: message,
        showConfirmButton: true
      })
    },

    async alerteEnvironnementTest() {
      await this.alerteErreur("Cette fonctionnalité est désactivée car vous travaillez sur un environnement de test.<br/>")
    },

    async alerteConfirmationEnregistrement(icone="warning", iconeColor="")
    {
      return await this.alerteConfirmation("Enregistrer les modifications", "Souhaitez-vous enregistrer vos modifications en cours ?", "Oui", "Non", icone, iconeColor)
    },

    async alerteConfirmationSuppression(titre, message, confirm, cancel, icone="warning", iconeColor="")
    {
      if (!confirm)
        confirm = "Supprimer"
      if (!cancel)
        cancel = "Annuler"

      return await this.alerteConfirmation(titre, message, confirm, cancel, icone, iconeColor, couleurs.rouge, couleurs.secondary)
    },

    async alerteConfirmation(titre, message, confirm, cancel, icone="warning", iconeColor="", confirmColor=couleurs.primary, cancelColor=couleurs.secondary )
    {
      if (!confirm)
        confirm = "Oui"
      if (!cancel)
        cancel = "Non"

      let result = await this.$swal.fire({
        title: titre,
        html: message,
        icon: icone,
        iconColor: iconeColor,
        showCancelButton: true,
        confirmButtonColor: confirmColor,
        cancelButtonColor: cancelColor,
        confirmButtonText: confirm,
        cancelButtonText: cancel,
        focusCancel: true,
        reverseButtons: true
      })
      return result.value
    },

    toastSucces(message) {
      this.$toast(message, {
        horizontalPosition: "center",
        verticalPosition: "top",
        className: ["toast-success"],
        duration: 1500
      })
    },
    toastErreur(message) {
      this.$toast(message, {
        horizontalPosition: "center",
        verticalPosition: "top",
        className: ["toast-error"],
        duration: 5000
      })
    }
  }
}