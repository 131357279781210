import Vue from "vue"
import Router from "vue-router"
import Home from "../views/Home"
import Profil from "../views/Profil"
import EnConstruction from "../views/EnConstruction"
import PropositionsMercuComparative from "../views/PropositionsMercuComparative"
import NotFound from "../views/NotFound"
import Spinner from "../components/Spinner"
import lscache from 'lscache'

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      meta: {
        title: "Accueil",
        message: "Bienvenue"
      },
      component: Home
    },
    {
      path: "/Home", redirect: { name: "Home" }
    },
    {
      path: "/spinner",
      name: "Spinner",
      meta: { title: "Chargement..." },
      component: Spinner
    },
    {
      path: "/en-construction",
      name: "EnConstruction",
      meta: { title: "En construction" },
      component: EnConstruction
    },
    {
      path: "/profil",
      name: "Profil",
      meta: { title: "Mon profil" },
      component: Profil
    },
    {
      path: "/:fournisseurId/PropositionsMercuComparative",
      name: "PropositionsMercuComparative",
      meta: {
        title: "PropositionsMercuComparative",
        message: "Tarifs pour la mercuriale comparative"
      },
      component: PropositionsMercuComparative,
      props: true
    },
    {
      path: "/*",
      name: 'NotFound',
      meta: { title: "Introuvable" },
      component: NotFound
    }
  ]
})

router.beforeEach(function (to, from, next) {
  // Nettoyage forcé du cache dans 20% de cas
  if (Math.random() < 0.2) {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(lscache.flushExpired);
    }
    else {
      lscache.flushExpired()
    }
  }

  //mise à jour du front
  var lastVersionCheck = localStorage.lastVersionCheck && JSON.parse(localStorage.lastVersionCheck)
  if (lastVersionCheck && lastVersionCheck.updated) {//mise à jour effectuée, on l'annonce
    Vue.toast('Application mise à jour', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      className: ['toast-success'],
      duration: 1500
    })
    lastVersionCheck.updated = false
    localStorage.lastVersionCheck = JSON.stringify(lastVersionCheck)
  }

  if (!lastVersionCheck || !lastVersionCheck.time || lastVersionCheck.time + 300000 < (new Date()).getTime()) {//max une vérification toutes les 5 minutes
    // eslint-disable-next-line promise/catch-or-return
    fetch(location.origin)//vérification de la version actuelle
      .then(r => r.text())
      .then(r => {
        var ts = r.substr(r.indexOf("data-build-timestamp=") + 22)
        ts = ts.substr(0, ts.indexOf("\""))
        lastVersionCheck = {
          time: (new Date()).getTime(),
          dateVersion: document.documentElement.dataset.buildTimestamp,
          updated: ts !== document.documentElement.dataset.buildTimestamp
        }
        localStorage.lastVersionCheck = JSON.stringify(lastVersionCheck)
        if (lastVersionCheck.updated) {
          window.location.replace(window.location.href)//mise à jour
        }
        return
      })
  }
  next()
})

export default router
