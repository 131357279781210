export default class userInfo {
  constructor(rawData = {}) {
    this.id = rawData.aspNetUserId
    this.email = rawData.email
    this.userName = (rawData.genreAbreviation ? rawData.genreAbreviation + " " : "") + (rawData.prenom ? rawData.prenom + " " : "") + rawData.nom
    this.fournisseurs = rawData.fournisseurs
    this.industriels = rawData.industriels
    this.typeContact = rawData.typeContact
    this.afficherPopupInfo = rawData.afficherPopupInfo
  }
}
