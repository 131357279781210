<template>
  <div>
    <nav class="header-navbar navbar-expand-md navbar navbar-semi-dark fixed-top">
      <div class="navbar-wrapper">
        <div class="navbar-container content">
          <div class="collapse navbar-collapse" id="navbar-mobile">
            <ul class="nav navbar-nav mr-auto float-left">
              <li @click="goHome()">
                <img class="logo" alt="EspaceFournisseur" src="../assets/images/logo/logo_agap_72dpi.png" />
              </li>

              <li :class="['nav-item',{'nav-item-btn':estMenuPrincipalSelectionne(entree)}]" v-for="entree in entrees" :key="entree.nom" :title="entree.aide">
                <div class="nav-item-principal">
                  <template v-if="entree.url">
                    <router-link :to="entree.url">
                      <span :class="[{'nav-item-selectionne':estMenuPrincipalSelectionne(entree)}]" aria-hidden="true">
                        {{ entree.titre }}
                        <i v-if="estMenuPrincipalSelectionne(entree) && entreesSecondairesFiltrees(menuPrincipalSelectionne).length" class="fas fa-chevron-down m-0 p-0"></i>
                      </span>
                    </router-link>
                  </template>
                </div>
                <div v-if="estMenuPrincipalSelectionne(entree) && entreesSecondairesFiltrees(menuPrincipalSelectionne).length" role="navigation" data-menu="menu-wrapper" data-nav="brand-center" class="navbar-expand-sm navbar navbar-horizontal navbar-light navbar-fixed navbar-secondaire">
                  <div data-menu="menu-container" class="main-menu-content center-layout">
                    <ul class="nav navbar-nav" data-menu="menu-navigation">
                      <li :class="['nav-item',{'nav-item-btn':estSelectionne(entree)}]" v-for="entree in entreesSecondairesFiltrees(menuPrincipalSelectionne)" :key="entree.nom" :title="entree.aide">
                        <template v-if="entree.url">
                          <!-- le menu Mercuriales est désactivé tant que les mercuriales ne sont pas chargées dans le store -->
                          <router-link :to="entree.url" :class="entree.url.name === 'Mercuriales' && !mercurialesChargees ? 'disabled' : ''" :event="entree.url.name === 'Mercuriales' && !mercurialesChargees ? '' : 'click'">
                            <span :class="[{'nav-item-selectionne':estSelectionne(entree)}]" aria-hidden="true">
                              {{ entree.titre }}
                            </span>
                          </router-link>
                        </template>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            
            <ul class="nav navbar-nav float-right">
              <li v-if="environnement !== 'prod'" class="nav-item mt-2 mr-3" :title="buildToolTip">
                <span class="text-danger">Environnement {{environnement}}</span>
              </li>
              <li v-if="utilisateurCourant && fournisseursCourants && fournisseursCourants.length > 1" class="mr-2">
                <SelectFournisseur :disabled="!peutChangerFournisseur()" :fournisseurId="fournisseurCourantId" @fournisseur-selectionne="changerFournisseur($event)" :key="userId" title="Fournisseur en cours" style="min-width:180px;max-width:450px" />
              </li>
              <li v-else-if="utilisateurCourant && fournisseursCourants && fournisseursCourants.length == 1" class="nav-item mt-2 mr-3">
                <span class="text-center">{{ userFournisseurUniqueName }}</span>
              </li>
              <li class="nav-item" style="position:relative;">
                <span id="headwayapp" class="nav-link" href=""><i class="ft-bell"></i></span>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'Profil'}" :title="'Mon profil ' + userName"><i class="ft-user"></i></router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'Configuration'}"><i class="ft-settings" title="Configuration"></i></router-link>
              </li> -->
              <li class="nav-item">
                <a class="nav-link" @click="logout" title="Déconnexion"><i class="ft-log-out"></i></a>
              </li>
            </ul>
          </div>
          <div v-if="entreesSecondairesFiltrees(menuPrincipalSelectionne).length" class="navbar-expand-sm navbar navbar-horizontal navbar-light navbar-fixed navbar-secondaire">
          </div>
        </div>
      </div>
    </nav>
    <div v-if="entreesSecondairesFiltrees(menuPrincipalSelectionne).length" class="navbar-double"></div>
  </div>
</template>

<script>
import SelectFournisseur from '@/components/Fournisseurs/SelectFournisseur.vue'
import { AUTH_LOGOUT } from "../store/actions/auth"
import { connecteData } from "@/mixins/connecteData"
import lscache from 'lscache'

export default {
  name: "topbar",
  components: { SelectFournisseur },
  mixins: [ connecteData ],
  data() {
    return {
      buildToolTip: `API: ${this.environnementApiDateBuild} \n` + this.versionFront(),
      environnement: process.env.VUE_APP_ENVIRONNEMENT //Variable à déclarer soit dans le fichier.env soit au niveau DevOps lors du déploiement
    }
  },
  computed: {
    entrees() {
      let entrees = [
        {
          nom: "Tarifs",
          titre: "Tarifs",
          aide: "Tarifs",
          url: { name: 'PropositionsMercuComparative', params: { fournisseurId: this.fournisseurCourantId } }
        }
      ]
      return entrees
    },
    entreesSecondaires() {
      let entreesSecondaires = [
        {
          nom: "MercurialeComparative",
          nomParent: "Tarifs",
          titre: "Mercuriale Comparative",
          aide: "Tarifs pour la mercuriale comparative",
          url: { name: 'PropositionsMercuComparative', params: { fournisseurId: this.fournisseurCourantId } }
        }
      ]

      return entreesSecondaires
    },
    userName() {
      return this.utilisateurCourant && this.utilisateurCourant.userName
    },
    userFournisseurUniqueName() {
      return this.fournisseursCourants[0].nom
    },
    menuPrincipalSelectionne() {
      let entreeSelectionnee = this.entrees.find(e => this.estSelectionne(e))
      if (entreeSelectionnee)
        return entreeSelectionnee
      let entreeSecondaireSelectionnee = this.entreesSecondaires.find(e => this.estSelectionne(e))
      if (entreeSecondaireSelectionnee)
        return this.entrees.find(e => e.nom === entreeSecondaireSelectionnee.nomParent)
      return null
    }
  },
  methods: {
    goHome() {
      this.$router.push("/")
    },
    async logout() {
      await this.$store.dispatch("auth/" + AUTH_LOGOUT)
      this.$router.push({ name: "Home" })
    },
    versionFront() {
      return `FRONT: ${document.documentElement.dataset.buildTimestamp} (ici => +${new Date().getTimezoneOffset() / (-60)}H)`
    },
    async changerFournisseur(fournisseurId) {
      if (this.fournisseurCourantId != fournisseurId) {
        let route = {
          name: this.$route.name,
          params: this.$route.params
        }
        if (route.params["fournisseurId"]) {
          route.params["fournisseurId"] = fournisseurId
          await this.$router.push({ name: "Home" })
          this.$router.push(route)
        }
        
        this.$store.commit('fournisseurs/setFournisseur', { fournisseurId: fournisseurId, userId: this.$store.state.user.userInfo.id })
        lscache.flush()
      }
    },
    estMenuPrincipalSelectionne(entree) {
      return entree?.nom === this.menuPrincipalSelectionne?.nom
    },
    estSelectionne(entree) {
      if (entree.url.name) {
        return this.$route.name === entree.url.name || entree.urlsCompatibles?.find(u => u === this.$route.name)
      }
      return this.$route.path === entree.url
    },
    entreesSecondairesFiltrees(entree) {
      if (!entree)
        return []
      let entreesFiltrees = this.entreesSecondaires.filter(e => e.nomParent === entree.nom)
      entreesFiltrees = entreesFiltrees.filter(e => {
        if (e.url) {
          return e.url.name != "EffectifsPortage";
        }
        else {
          return e;
        }
      })
      return entreesFiltrees
    },
    peutChangerFournisseur() {
      return true //this.$route.name != "PropositionsMercuComparative"
    }
  },
  mounted() {
  }
}
</script>
