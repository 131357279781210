<template>
  <div id="app" data-app>
    <Spinner message="Traitement en cours" v-if="spinnerShown" />
    <topbar v-if="estAuthentifie && fournisseurCourantId" />
    <div v-if="estAuthentifie && fournisseurCourantId" class="app-content content">
      <div class="content-wrapper">
        <div class="content-body">
          <router-view :key="$route.fullPath" />
        </div>
      </div>
    </div>
    <div v-else>
      <b-modal v-model="afficherLogin" no-close-on-backdrop hide-header hide-footer>
        <Login />
      </b-modal>
    </div>
  </div>
</template>
<script>
import topbar from "@/components/TopBar";
import Spinner from '@/components/Spinner'
import Login from '@/views/Login'
import { mapGetters } from "vuex";
import { connecteData } from "@/mixins/connecteData"
import { objetVide } from '@/mixins/index'

export default {
  name: "app",
  components: { topbar, Spinner, Login },
  mixins: [objetVide, connecteData],
  data() {
    return {
      notificationSystem: {
        options: {
          error: {
            position: "topRight",
            timeout: 0
          }
        }
      }
    };
  },
  watch: {
    '$route'(to /*, from*/) {
      document.title = to.meta.title || 'Espace Fournisseur'
    }
  },
  mounted() {
    console.log("Dans le Mounted de APP.Vue")
  },
  updated() {
   
  },
  computed: {
    ...mapGetters("auth", ["estAuthentifie"]),
    afficherLogin() {
      return !(this.fournisseurCourantId && this.estAuthentifie)
    },
    spinnerShown() {
      return this.$store.getters['uxHelpers/spinnerShown']
    }
  },
  methods: {
    disalowClickOutside(e) {
      e.preventDefault()
    },
  }
}
</script>

<style lang="scss">
@import "./assets/css/style.scss";
@import "./assets/css/font-awesome5.css";
</style>
