<template>
  <div style="display:inline">
    <div class="text-center mb-2">
      <img class="logo mt-4" alt="EspaceFournisseur" src="../assets/images/logo/logo_agap_72dpi.png" />
    </div>
    <div class="font-medium-4 text-center mb-4">
      Bienvenue sur votre espace fournisseur
    </div>

    <form @submit.prevent="login">
      <div v-if="erreurLogin">
        <div class="alert alert-warning" role="alert">
          {{ erreurLogin }}
        </div>
      </div>
      <div v-if="!afficherChoixFournisseur">
        <div class="form-group mb-0">
          <label for="user-name">Nom d'utilisateur</label>
          <fieldset class="position-relative has-icon-left">
            <input v-model="userName" type="text" required class="form-control" id="user-name" autofocus autocomplete="username" placeholder="Nom d'utilisateur" ref="login-username" />
            <div class="form-control-position" style="top:0;">
              <i class="fas fa-user"></i>
            </div>
          </fieldset>
        </div>
        <div class="form-group mb-2">
          <label for="user-password">Mot de passe</label>
          <fieldset class="position-relative has-icon-left">
            <input v-model="password" :type="afficherMdP ? 'text' : 'password'" required class="form-control" id="user-password" autocomplete="current-password" placeholder="Mot de passe" />
            <div class="form-control-position" style="top:0;">
              <i class="fas fa-key"></i>
            </div>
            <div class="form-control-position" style="top:0;right:0;cursor:pointer;" :title="afficherMdP ? 'masquer le mot de passe' : 'afficher le mot de passe'" @click="afficherMdP = !afficherMdP">
              <i :class="afficherMdP ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
            </div>
          </fieldset>
        </div>
      </div>
      <SelectFournisseur v-else @fournisseur-selectionne="changerFournisseur($event)" />
      <div v-if="erreur" class="erreur">{{erreur}}</div>
      <button v-if="!afficherChoixFournisseur" type="submit" class="btn btn-primary btn-block" :disabled="isLoading" :class="{ boutonDesactive: isLoading }">
        <i :class="['fas', 'fa-lock', {'fa-spin':isLoading}]"></i>
        <span class="ml-2">Connexion</span>
      </button>
    </form>

    <div v-if="!afficherChoixFournisseur" class="mt-2">
      <span class="float-right font-small-3"><a class="card-link" href="https://adherents.agap.pro/Identity/Account/ForgotPassword">Mot de passe oublié ?</a></span>
    </div>
  </div>
</template>

<script>
import vm from "@/helpers/vm-toolkit-vue"
import lscache from 'lscache'
import SelectFournisseur from '@/components/Fournisseurs/SelectFournisseur.vue'
//import { logConnexion } from '@/api/gpao/user'
import { AUTH_REQUEST } from "@/store/actions/auth"
import { connecteData } from "@/mixins/connecteData"
import { showAlerts } from "@/mixins/alerts"

export default {
  name: "login",
  mixins: [ connecteData, showAlerts],
  components: { SelectFournisseur },
  data() {
    return {
      isLoading: false,
      userName: "",
      password: "",
      erreurLogin: "",
      erreur: false,
      afficherMdP: false,
      afficherChoixFournisseur: false
    };
  },
  created() {
    this.nettoyerCache()
    var env = vm.whereAmI()
    if (env.browser === "ie" || env.browser === "edge") {
      this.erreur = "Votre navigateur est obsolète et le fonctionnement n'est pas garanti avec notre application."
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs["login-username"].focus()
    })
  }
  ,
  computed: {

  },
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max))
    },
    nettoyerCache() {
      lscache.flush()
    },
    async login() {
      const { userName, password } = this
      this.isLoading = true
      this.erreurLogin = ""

      try {
        this.$store.commit('profils/emptyProfilId')
        const error = await this.$store.dispatch('auth/' + AUTH_REQUEST, { userName, password })

        if (error) {
          throw error
        }
      } catch (error) {
        this.isLoading = false
        if (error.message && error.message == "Network Error") {
          this.erreurLogin = "Erreur réseau / connexion internet, ou serveur manquant"
        } else {
          if (!error.response) {
            this.erreurLogin = error.message
            await this.alerteErreur(this.erreurLogin)
          }
          else
            this.erreurLogin = error.response.data.message
        }

        console.error(error)
        return
      }

      //logConnexion()

      if (this.fournisseursCourants?.length == 1) {
        this.$store.commit('fournisseurs/setFournisseur', { fournisseurId: this.fournisseursCourants[0].fournisseurId, userId: this.userId })
      }
      else {
        this.afficherChoixFournisseur = true
      }
    },
    changerFournisseur(fournisseurId) {
      this.$store.commit('fournisseurs/setFournisseur', { fournisseurId: fournisseurId, userId: this.$store.state.user.userInfo.id })
      lscache.flush()
    },
    changerProfil(profilId) {
      this.$store.commit('profils/setProfil', { profilId: profilId, userId: this.$store.state.user.userInfo.id })
      lscache.flush()
    },
  }
}
</script>
