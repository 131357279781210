<template>
  <b-modal ref="modalImportFichierCotation" scrollable size="lg" title="Importer un fichier de cotation"
      ok-title="Fermer"
      :ok-only=true
      :ok-disabled="!resultatImportFichier"
      no-close-on-backdrop
      @show="initialiserModale()"
      @shown="importerFichierCotation()"
      @ok="fermerImport()">
      <template slot="default">
        <div style="display: inline">
          <div class="container-fluid card card-body">
            <div class="row">
              <div class="col-12 form-group">
                <label>Nom du fichier à importer : </label>{{ nomFichier }}
              </div>
            </div>
          </div>

          <div v-if="!resultatImportFichier" class="container-fluid card card-body">
            <div class="row">
              <div class="col-12 form-group">
                <i class="fas fa-spinner fa-spin"></i>{{ etatImportFichier }}
              </div>  
            </div>
          </div>

          <div v-else> <!--On affiche une ligne par fichier avec le résultat général-->
            <div class="container-fluid card card-body accordion collapse-icon accordion-icon-rotate">
              <template v-for="(resultat, iResultat) in resultatImportFichier">
                
                <div class="row" :key="'result-'+iResultat" :id="'nomFichier-'+iResultat" data-toggle="collapse" :href="'#detailErreur-'+iResultat">
                  <div class="col-12 form-group">
                    <i :class="[{'fas fa-exclamation-circle text-error':resultat && !resultat.fichierValide},
                                {'fas fa-check-circle text-success':resultat && resultat.fichierValide && !resultat.listeErreurs.some(x => x.warning)},
                                {'fas fa-exclamation-circle text-warning':resultat && resultat.fichierValide && resultat.listeErreurs.some(x => x.warning)}]"></i>
                    <span>{{ resultat.etatImportFichier }}</span><u v-if="(resultat && !resultat.fichierValide) || (resultat && resultat.fichierValide && resultat.listeErreurs.some(x => x.warning))" class="ml-1" style="cursor:pointer;">(voir détail)</u>
                  </div>
                </div>

                <div v-if="!resultat.fichierValide || (resultat.fichierValide && resultat.listeErreurs.some(e => e.warning) )" :id="'detailErreur-'+iResultat" :aria-labelledby="'detailErreur-'+iResultat" class="row collapse" :key="'resultErreur-'+iResultat">
                  <div class="col-12 form-group">
                    <table class="table table-bordered table-sm text-center">
                      <colgroup>
                        <col width="200">
                        <col>
                      </colgroup>
                      <thead class="table-header-secondary">
                        <th>Désignation Produit</th>
                        <th>Message d'erreur</th>
                      </thead>
                      <tbody>
                        <template v-for="(msg, iMsg) in resultat.listeErreurs">
                          <tr class="separateur" :key="'msg-'+iMsg">
                            <td class="aligner-gauche">
                              {{ msg.designationAgap }}
                            </td>
                            <td class="aligner-gauche">
                              {{ msg.erreur }}
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div v-if="resultatImportFichier && resultatImportFichier.some(x => x.fichierValide)" class="container-fluid card card-body">
            <div class="row">
              <div class="col-12 form-group">
                <em>
                  <p>
                    Merci de vérifier vos propositions avant de les envoyer
                  </p>
                </em>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
</template>

<script>

import { importerPropositions, copierFichesTechniques } from '@/api/gpao/propositions'
import { connecteData } from "@/mixins/connecteData"

export default {
  name: "ModalImportFichierCotation",
  props: {
    datePropoId: {type: Number},
    nomFichier: {type: String},
    contenuFichier: {type: FormData}
  },
  mixins: [ connecteData ],
  data() {
    return {
      resultatImportFichier: null,
      etatImportFichier: "",
    }
  },
  watch: {
    nomFichier() {
      this.initialiserModale()
    },
  },
  computed: {

  },
  methods: {

    initialiserModale() {
      //on affiche la modale de suivi du chargement avec l'etat initial
      this.etatImportFichier = "Controle et chargement du fichier en cours ..."
      this.resultatImportFichier = null
    },

    async copierFT(listeFichier) {
      if (listeFichier.length > 0) {
        this.$emit("messageFT", "Téléchargement des fiches techniques en cours", false)
        var toGet=[];
        listeFichier.forEach(f => {
          toGet.push(copierFichesTechniques(this.fournisseurCourantId, this.datePropoId, f.familleId))
        })

        await Promise.all(toGet)

        this.$emit("messageFT", "Téléchargement des fiches techniques terminé", true)
      } else {
        this.$emit("messageFT", "Chargement des propositions terminé", true)
      }
      
    },
    //Méthode appelé par l'evenement opened (shown) de la modal
    async importerFichierCotation() {
      //on appel l'API de chargement du fichier et on affiche le résultat dans la modale, via la variable resultatImportFichier
      let resp = await importerPropositions(this.fournisseurCourantId, this.datePropoId, this.contenuFichier);
      this.resultatImportFichier = resp.data
      //On lance le téléchargement des fiches Techniques pour les fichiers OK (ou en Warning) en asynchrone et avec des liens de FT à traiter
      this.copierFT(this.resultatImportFichier.filter(f => f.fichierValide && f.fichesTechniquesATraiter))
    },
    fermerImport() {
      this.$emit("rechargerPropo")
      this.hide()  
    },
    show() {
      this.$refs.modalImportFichierCotation.show()
    },
    hide() {
      this.$refs.modalImportFichierCotation.hide()
    }
  }
}
</script>