import { USER_INFO, USER_ERROR, USER_SUCCESS, USER_LOGOUT } from "../actions/user"
import Vue from "vue"
import { currentUserInfo } from "@/api/gpao/user"
import userInfo from "../../models/userInfo"


export default {
  namespaced: true,

  state: {
    status: "",
    userInfo: {}
  },

  getters: {
    utilisateurCourant: state => state.userInfo,
    [USER_INFO]: state => state
  },

  actions: {
    [USER_INFO]: async function ({ commit }) {
      try {
        await commit(USER_INFO)  

        const resp = await currentUserInfo()

        //Si on n'a pas trouvé de contact avec l'Id de l'utilisateur on déconnect
        console.log("### Retour current User Info : " + resp.data)
        if (resp.data === "\"\"") {
          throw new Error("Aucun contact ne correspond à votre nom d'utilisateur")
        }
          

        commit(USER_SUCCESS, resp)
      } catch(err) {
        
        console.log('Erreur Get User USER_INFO')
        await commit(USER_ERROR)
        console.error(err)

        throw err
      }
    }
  },

  mutations: {
    [USER_INFO]: state => {
      state.status = "loading"
    },
    [USER_SUCCESS]: (state, resp) => {
      state.status = "success"
      Vue.set(state, "userInfo", new userInfo(resp.data))
    },
    [USER_ERROR]: state => {
      state.status = "error"
    },
    [USER_LOGOUT]: state => {
      console.log("LOGOUT")
      state.userInfo = {}
      window.location = "/"//recharge la page pour éviter "l'écran blanc" après un logout
    },
    setAfficherPopupInfo: function (state, opt) {
      Vue.set(state.userInfo, "afficherPopupInfo", opt)
    }
  }
}
