<template>
  <div v-if="afficher" class="card">
    <div class="card-body flex-center-space-between">
      <!-- Message de gauche -->
      <div class="flex-start barre-info-gauche">
        <template v-for="(message, iMessage) in informations.messagesGauche">
          <span :key="'gauche' + iMessage">
            <i v-if="message.icone" :class="message.icone + ' mr-1'"></i>
            <a v-if="message.action" :class="message.class" @click="message.action">{{ message.texte }}</a>
            <span v-else :class="message.class + ' mr-1'">{{ message.texte }}</span>
          </span>
        </template>
      </div>
      <!-- Message du milieu -->
      <div class="flex-center-center">
        <template v-for="(message, iMessage) in informations.messagesCentre">
          <span :key="'centre' + iMessage">
            <i v-if="message.icone" :class="message.icone + ' mr-1'"></i>
            <a v-if="message.action" :class="message.class" @click="message.action">{{ message.texte }}</a>
            <span v-else :class="message.class + ' mr-1'">{{ message.texte }}</span>
          </span>
        </template>
      </div>
      <!-- Message de droite -->
      <div class="flex-end barre-info-droite">
        <template v-for="(message, iMessage) in informations.messagesDroite">
          <span :key="'droite' + iMessage">
            <i v-if="message.icone" :class="message.icone + ' mr-1'"></i>
            <a v-if="message.action" :class="message.class" @click="message.action">{{ message.texte }}</a>
            <span v-else :class="message.class + ' mr-1'">{{ message.texte }}</span>
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BarreInformations",
  props: [ "informations" ],  // Attention ! le paramètre "informations" doit être computed dans le composant parent pour avoir un message dynamique
  computed: {
    afficher() {
      let visible = this.informations?.messagesGauche?.length || this.informations?.messagesCentre?.length || this.informations?.messagesDroite?.length
      this.$emit("visibility-changed", visible)
      return visible
    }
  },
  mounted() {
    this.$emit("visibility-changed", this.afficher)
  }
}
</script>
