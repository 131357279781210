<template>
  <div>
    <div v-if="!fournisseurId" class="form-group required mb-0 pb-0">
      <label>Choisissez le fournisseur pour lequel vous allez travailler...</label>
    </div>
    <v-select v-if="fournisseursCourants" v-model="fournisseurChoisi" :options="optionsFournisseurs" value="fId" label="fRS"
      placeholder="Fournisseurs..." :clearable="false" :disabled="disabled" @input="setFournisseur($event.fId)">
    </v-select>
  </div>
</template>

<script>
import { objetVide } from '@/mixins/index'
import { connecteData } from "@/mixins/connecteData"

export default {
  props: ['fournisseurId', 'disabled'],
  mixins: [objetVide, connecteData],
  data() {
    return {
      fournisseurChoisi: null
    }
  },
  computed: {
    optionsFournisseurs() {
      let result = []
      this.fournisseursCourants.map(el => {
        result.push({ fId: el.fournisseurId, fRS: el.nom })
      })
      result.sort((a,b) => { return a.fRS.localeCompare(b.fRS) })
      return result;
    }
  },
  methods: {
    setFournisseur(event) {
      if (event) {
        this.$emit('fournisseur-selectionne', event)
      }
    }
  },
  mounted() {
    if (this.fournisseurId) {
      this.fournisseurChoisi = this.optionsFournisseurs.find(f => f.fId === this.fournisseurId)
    }
  }
}
</script>
