<template>
  <div class="container-fluid">
    Page d'accueil
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { connecteData } from "@/mixins/connecteData"

export default {
  name: "Home",
  mixins: [connecteData],
  components: {},
  data() {
    return {
      informations: []
    }
  },
  methods: {
    ...mapActions("uxHelpers", ["cacherAttente"])
  }
}
</script>
