import { mapGetters } from 'vuex'

/* Data du connecté */
export const connecteData = {
  computed: {
    /*...mapGetters("auth",["isAdmin"]),
    ...mapGetters("auth",["isAdminGPAO"]),
    ...mapGetters("auth",["isUtilisateur"]),
    ...mapGetters("auth",["isLecteur"]),
    ...mapGetters("auth",["isMercuA"]),
    ...mapGetters("auth",["isMercuC"]),
    ...mapGetters("auth",["isMercuD"]),
    ...mapGetters("auth",["isMercuE"]),
    ...mapGetters("auth",["isMercuM"]),
    ...mapGetters("auth",["isMercuP"]),
    ...mapGetters("auth",["isMercu1"]),
    ...mapGetters("auth",["isDifferenteMercu"]),*/
    ...mapGetters({fournisseurCourantId:"fournisseurs/fournisseurCourantId"}),
    ...mapGetters({fournisseurCourant:"fournisseurs/fournisseurCourant"}),
    ...mapGetters({utilisateurCourant:"user/utilisateurCourant"}),
    /*...mapGetters({profilCourantId:"profils/profilId"}),*/
    userId() {
      if (!this.utilisateurCourant)
        return undefined;
      return this.utilisateurCourant.id;
    },
    fournisseursCourants() {
      return this.utilisateurCourant?.fournisseurs ?? []
    },
    industrielsCourants() {
      return this.utilisateurCourant?.industriels ?? []
    },
    fournisseursEtIndustrielsCourants() {
      let fu = [...this.fournisseursCourants]
      fu.push(...this.industrielsCourants)
      return fu
    }
  }
}
