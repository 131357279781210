<template>
  <b-modal ref="modalAfficheDetailFT" scrollable size="lg" content-class="md-ft" title="Fiche Technique"
      ok-title="Fermer"
      :ok-only=true
      @show="initialiserModale()"
      >
      <template slot="default">
        <div style="display: inline">
          <div class="container-fluid card card-body">
            <div class="row">
              <div class="col-12 form-group">
                <label>Certaines fiches techniques n'ont pu etre importées : </label>
              </div>
            </div>
            <div class="row">
              <div class="col-12 form-group">
                <table class="table table-bordered table-sm text-center">
                  <colgroup>
                    <col width="140">
                    <col width="200">
                    <col>
                  </colgroup>
                  <thead class="table-header-secondary">
                    <th>Reference</th>
                    <th>Désignation Produit</th>
                    <th>Message d'erreur</th>
                  </thead>
                  <tbody>
                    <template v-for="(msg, iMsg) in listeErreurs">
                      <tr class="separateur" :key="'msg-'+iMsg">
                        <td class="aligner-gauche">
                          {{ msg.propositionEnCours.reference }}
                        </td>
                        <td class="aligner-gauche">
                          {{ msg.propositionEnCours.designation }}
                        </td>
                        <td class="aligner-gauche">
                          {{ msg.propositionEnCours.erreurAzureFicheTechnique ?? "Pas de fiche technique importée" }}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>     
        </div>
        
      </template>
    </b-modal>
</template>

<script>

import { connecteData } from "@/mixins/connecteData"

export default {
  name: "ModalAfficheDetailFT",
  props: {
    listeErreurs: {type: Array},
  },
  mixins: [ connecteData ],
  data() {
    return {
      lienAfficheFT: null
    }
  },
  computed: {
    
  },
  methods: {
    initialiserModale() {
      
    },

    show() {
      this.$refs.modalAfficheDetailFT.show()
    },
    hide() {
      this.$refs.modalAfficheDetailFT.hide()
    }
  }
}
</script>

<style>
.md-ft {
  height: 94vh;
}
.md-ft .container-fluid,
.md-ft object {
  height: 100%;
  width: 100%;
}
</style>