<template :key="key">
  <div ref="main" style="position:relative;">
     <v-select v-if="!maxDisplay || focused || !optionsFake.length" :multiple="multiple" ref="select" :style="'position:absolute;width:' + ((clearText && optionsChoisies.length) ? 'calc(100% - 40px);' : '100%;')"
      :append-to-body="!!dropdownPosition" :calculate-position="calcPosition"
      :disabled="disabled"
      :clearable="true"
      :value="optionsChoisies"
      :options="optionsDisponibles"
      :placeholder="placeholder"
      :closeOnSelect="!multiple"
      @input="handleInput"
      @search:focus="handleFocus"
      @search:blur="handleBlur"
      @search="handleSearch">
        <template v-slot:no-options>
          {{texteVide}}
        </template>
        <template v-slot:selected-option="option">
          <span :class="option.class" :style="option.style"><span></span><i v-if="option.icone" :class="option.icone"></i>{{ option.label }}<span></span></span>
        </template>
        <template v-slot:option="option">
          <span :class="option.class" :style="option.style"><span></span><i v-if="option.icone" :class="option.icone"></i>{{ option.label }}<span></span></span>
        </template>
    </v-select>
    <div v-else><!-- "faux" select utilisé pour l'affichage en taille limitée -->
      <v-select :multiple="multiple" :style="'position:absolute;width:' + ((clearText && optionsChoisies.length) ? 'calc(100% - 40px);' : '100%;')"
        :append-to-body="!!dropdownPosition" :calculate-position="calcPosition"
        :disabled="disabled"
        :clearable="true"
        :value="optionsFake"
        :options="optionsDisponibles"
        :placeholder="placeholder"
        :closeOnSelect="!multiple"
        @input="handleFocus2"
        @search:focus="handleFocus2">
          <template v-slot:no-options>
            {{texteVide}}
          </template>
          <template v-slot:selected-option="option">
            <span :class="option.class" :style="option.style"><span></span><i v-if="option.icone" :class="option.icone"></i>{{ option.label }}<span></span></span>
          </template>
          <template v-slot:option="option">
            <span :class="option.class" :style="option.style"><span></span><i v-if="option.icone" :class="option.icone"></i>{{ option.label }}<span></span></span>
          </template>
      </v-select>
      <span style="position:absolute;right:44px;bottom:-3px;font-weight:bold;color:gray;font-size:25px;" @click="handleFocus2">...</span>
    </div>
    
    <b-button v-if="clearText && optionsChoisies.length" v-tooltip="{ content: clearText, placement: 'top' }" class="btn btn-secondary" style="width:35px;height:35px;margin:3px 5px;flex:0 0 35px;position:absolute;right:-10px;top:0;" @click="handleClear">
      <i class="fas fa-times"></i>
    </b-button>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
export default {
  name: "MultiSelect",/*Ce composant permet de centraliser le modifications apportées à vue-select pour le rendre plus ergonomique :
    - mise à jour des options disponibles en fonction de celles déjà sélectionnées,
    - simplification de la déclaration des options (plus besoin de déclarer une fonction reduce...),
    - traduction du message affiché quand aucune option n'est disponible,
    - affichage du bouton pour vider avec le paramètre clearText,
    - possibilité de limiter les choix affichés (hors édition) avec le paramètre maxDisplay.
*/
  props: {
    value: {type: Array, default: () => []},
    items: {type: Array, required: true},
    itemText: {type: String, default: () => "text"},
    itemValue: {type: String, default: () => "value"},
    itemClass: {type: String, default: () => "class"},
    itemStyle: {type: String, default: () => "style"},
    itemIcone: {type: String, default: () => "icone"},
    maxDisplay: {type: Number, default: () => 0},//si cette prop est > 0, limiter les choix affichés
    disabled: {type: Boolean, default: () => false},
    placeholder: {type: String, default: () => "Veuillez sélectionner..."},
    emptyText: {type: String, default: () => "Toutes les options sont déjà sélectionnées."},
    multiple: {type: Boolean, default: true},
    noResultText: {type: String, default: () => "Pas de résultat"},
    clearText: {type: String},//si cette prop est renseignée, affiche un bouton permettant de vider la sélection
    dropdownPosition: {type: String, default: () => undefined}//si renseigné, utilise poper.js pour positionner le menu ; TODO: bugfix z-index si dans un modal
  },
  data() {
    return {
      key: 1,
      focused: false,
      optionsChoisies: [],
      optionsFake: [],
      optionsDisponibles: [],
      optionsToutes: [],
      optionsOnFocus: [],
      search: "",
      texteVide: ""
    }
  },
  watch: {
    "value": function (val) {
      if (val && !val.length) {
        this.optionsChoisies = []
        this.optionsDisponibles = this.optionsToutes
      }
    },
    "items": function() {
      this.initOptions()  
    }
  },
  computed: {
    options() {
      return this.optionsChoisies.map(o => o.value)
    }
  },
  methods: {
    updateFake() {
      var fake = []
      if (this.optionsChoisies.length > this.maxDisplay) {
        for (let i = 0; i < this.maxDisplay; i++) {
          fake.push(this.optionsChoisies[i])
        }
      }
      this.optionsFake = fake
    },
    handleClear() {
      this.handleFocus()
      this.$nextTick(() => this.handleInput([]))
    },
    handleInput(actives) {
      this.$refs.select.$el.querySelector("input").focus()//ouvre le menu lors d'une suppression (nécessaire pour la gestion du blur)
      this.focused = true
      var choisies = []
      var disponibles = []
      this.optionsToutes.forEach(function (o) {
        if (this.multiple) {
          if (actives.find(a => a.value === o.value)) {
            choisies.push(o)
          } else {
            disponibles.push(o)
          }
        } else {
          if (actives && actives.value === o.value) {
            choisies = [o]
          } else {
            disponibles.push(o)
          }
        }
      }.bind(this))
      this.optionsChoisies = choisies
      this.optionsDisponibles = disponibles
      this.updateFake()
      this.key += 1
      this.$emit('change', this.options)
      this.$emit('input', this.options)
    },
    handleFocus() {
      if (!this.focused) {
        this.optionsOnFocus = this.options
      }
      this.focused = true
      this.$emit('focus', this.options)
    },
    handleFocus2() {
      if (!this.focused) {
        this.optionsOnFocus = this.options
      }
      this.focused = true

      this.$nextTick(() => this.$refs.select.$el.querySelector("input").focus())//ouvre le vrai menu
      this.$emit('focus', this.options)
    },
    handleBlur() {//si les options n'ont pas changé, l'event est envoyé avec false
      this.focused = false
      if (JSON.stringify(this.optionsOnFocus) === JSON.stringify(this.options)) {
        this.$emit('blur', false)
      } else {
        this.$emit('blur', this.options)
      }
    },
    handleSearch(search) {
      this.search = search
      if (this.search) {
        this.texteVide = this.noResultText
      } else {
        this.texteVide = this.emptyText
      }
    },
    initOptions() {
      var toutes = []
      var choisies = []
      var disponibles = []
      this.items.forEach(function (o) {
        var option
        if (typeof o === "string" || typeof o === "number") {
          option = {
            label: o,
            value: o
          }
        } else {
          option = {
            label: o[this.itemText],
            value: o[this.itemValue],
            class: o[this.itemClass],
            style: o[this.itemStyle],
            icone: o[this.itemIcone]
          }
        }
        toutes.push(option)
        if (this.value && this.value.find(v => v === option.value)) {
          choisies.push(option)
        } else {
          disponibles.push(option)
        }
      }.bind(this))
      this.optionsToutes = toutes
      this.optionsChoisies = choisies
      this.optionsDisponibles = disponibles
      this.texteVide = this.emptyText
      this.updateFake()
      this.key += 1
    },
    async fixHeight() {
      await this.$nextTick()
      var main = this.$refs.main
      var heightSel = main && main.querySelector(".v-select")
      if (heightSel) {
        heightSel = heightSel.getBoundingClientRect().height
      }
      var heightCombo = main && main.querySelector("[role='combobox']")
      if (heightCombo) {
        heightCombo = heightCombo.getBoundingClientRect().height
        if (heightSel && heightCombo < heightSel) {
          heightCombo = heightSel
        }
        var newHeight = heightCombo + "px"
        if (main.style.height !== newHeight) {
          main.style.height = newHeight
          this.key += 1
        }
      }
    },
    calcPosition(dropdownList, component, { width }) {//https://vue-select.org/guide/positioning.html#popper-js-integration
      if (!this.dropdownPosition) {
        return
      }
      dropdownList.style.width = width
      var placement = this.dropdownPosition
      if (placement === "bottom-top") {//avec cette option, le dropdown est aligné verticalement (en haut ou en bas, pas sur le coté)
        var rect = component.$refs.toggle.getBoundingClientRect()
        if (rect.top + rect.bottom > window.innerHeight) {
          placement = "top"
        } else {
          placement = "bottom"
        }
      }

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            }
          }
        ]
      })

      return () => popper.destroy()// called just before dropdown is removed from DOM
    }
  },
  mounted() {
    this.initOptions()
  },
  updated() {
    this.fixHeight()
    setTimeout(() => {
      return this.fixHeight()
    }, 500)
  }
}
</script>
