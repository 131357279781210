<template>
  <b-modal ref="modalImportFichesTEchniques" scrollable size="lg" title="Importer des fiches Techniques"
      ok-title="Fermer"
      :ok-only=true
      :ok-disabled="!resultatImportFichier"
      no-close-on-backdrop
      @show="initialiserModale()"
      @shown="importerFichesTechniques">
      <template slot="default">
        <div style="display: inline">
          <div class="container-fluid card card-body">
            <div class="row">
              <div class="col-12 form-group">
                <label>Nom du fichier à importer : </label>{{ nomFichier }}
              </div>
            </div>
          </div>

          <div v-if="!resultatImportFichier" class="container-fluid card card-body">
            <div class="row">
              <div class="col-12 form-group">
                <i class="fas fa-spinner fa-spin"></i>{{ etatImportFichier }}
              </div>  
            </div>
          </div>

          <div v-else> <!--On affiche une ligne par fichier avec le résultat général-->
            <div class="container-fluid card card-body accordion collapse-icon accordion-icon-rotate">
              <template v-for="(resultat, iResultat) in resultatImportFichier">
                
                <div class="row" :key="'result-'+iResultat" :id="'nomFichier-'+iResultat" data-toggle="collapse" :href="'#detailErreur-'+iResultat">
                  <div class="col-12 form-group">
                    <i :class="[{'fas fa-exclamation-circle text-error':resultat && !resultat.fichierValide},
                                {'fas fa-check-circle text-success':resultat && resultat.fichierValide && !resultat.listeErreurs.some(x => x.warning)},
                                {'fas fa-exclamation-circle text-warning':resultat && resultat.fichierValide && resultat.listeErreurs.some(x => x.warning)}]"></i>
                    <span>{{ resultat.etatImportFichier }}</span><u v-if="resultat && !resultat.fichierValide" class="ml-1" style="cursor:pointer;">(voir détail)</u>
                  </div>
                </div>

                <div v-if="!resultat.fichierValide || (resultat.fichierValide && resultat.listeErreurs.some(e => e.warning) )" :id="'detailErreur-'+iResultat" :aria-labelledby="'detailErreur-'+iResultat" class="row collapse" :key="'resultErreur-'+iResultat">
                  <div class="col-12 form-group">
                    <table class="table table-bordered table-sm text-center">
                      <colgroup>
                        <col width="200">
                        <col>
                      </colgroup>
                      <thead class="table-header-secondary">
                        <th>Désignation Produit</th>
                        <th>Message d'erreur</th>
                      </thead>
                      <tbody>
                        <template v-for="(msg, iMsg) in resultat.listeErreurs">
                          <tr class="separateur" :key="'msg-'+iMsg">
                            <td class="aligner-gauche">
                              {{ msg.designationAgap }}
                            </td>
                            <td class="aligner-gauche">
                              {{ msg.erreur }}
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div v-if="resultatImportFichier && resultatImportFichier.some(x => x.fichierValide)" class="container-fluid card card-body">
            <div class="row">
              <div class="col-12 form-group">
                <em>
                  <p>
                    Merci de vérifier vos propositions avant de les envoyer
                  </p>
                </em>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
</template>

<script>

import { importerFichesTechniques, importerUneFicheTechnique } from '@/api/gpao/propositions'
import { connecteData } from "@/mixins/connecteData"

export default {
  name: "ModalImportFichesTechniques",
  props: {
    datePropoId: {type: Number},
    familleId: {type: Number},
    propoArticleId: {type: Number},
    nomFichier: {type: String},
    contenuFichier: {type: FormData}
  },
  mixins: [ connecteData ],
  data() {
    return {
      resultatImportFichier: null,
      etatImportFichier: "",
    }
  },
  watch: {
    nomFichier() {
      this.initialiserModale()
    },
  },
  computed: {

  },
  methods: {

    initialiserModale() {
      //on affiche la modale de suivi du chargement avec l'etat initial
      this.etatImportFichier = "Controle et chargement du fichier en cours ..."
      this.resultatImportFichier = null
    },
    messageFichier(resultat) {
      return resultat.etatImportFichier + (((resultat && !resultat.fichierValide) || (resultat && resultat.fichierValide && resultat.listeErreurs.some(x => x.warning))) ? "  <u>(voir détail)</u>" : "" )  
    },
    //Méthode appelé par l'evenement opened (shown) de la modal
    async importerFichesTechniques() {
      //on appel l'API de chargement des fiches techniques et on affiche le résultat dans la modale, via la variable resultatImportFichier
      //Si on a cliqué sur le bouton pour importer une fiche texchnique sur une référence, on appel l'autre API
      if (this.propoArticleId) {
        //fournisseurId, propositionId, propoArticleId, fichier
        let resp = await importerUneFicheTechnique(this.fournisseurCourantId, this.datePropoId, this.propoArticleId, this.contenuFichier)
        this.resultatImportFichier = resp.data
      } else {
        let resp = await importerFichesTechniques(this.fournisseurCourantId, this.datePropoId, this.familleId, this.contenuFichier)
        this.resultatImportFichier = resp.data
      }
      
      this.$emit("rechargerPropo")
    },

    show() {
      this.$refs.modalImportFichesTEchniques.show()
    },
    hide() {
      this.$refs.modalImportFichesTEchniques.hide()
    }
  }
}
</script>