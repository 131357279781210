import service from '@/helpers/serviceGPAO'

export function obtenirStatutsPropositions() {
  return service.get("/propositions/ObtenirStatutsPropositions")
}

export function obtenirDatesPropositionsOuvertes(fournisseurId, ouvertesSeulement) {
  return service.get("/propositions/Fournisseur/" + fournisseurId + "/ObtenirListeDatesPropositions/" + ouvertesSeulement)
}

export function obtenirFamillesProposition(fournisseurId, propositionId) {
  return service.get("/propositions/Fournisseur/" + fournisseurId + "/ObtenirListeFamilles/" + propositionId)
}

export function obtenirPropositions(fournisseurId, propositionId, familleId) {
  return service.get("/propositions/Fournisseur/" + fournisseurId + "/ObtenirPropositions/" + propositionId + "/" + familleId)
}

export function obtenirTrameCotation(fournisseurId, propositionId, listeFamilleId) {
  return service.post("/propositions/Fournisseur/" + fournisseurId + "/DemandeCotation/" + propositionId, listeFamilleId, { responseType: "blob" })
}

export function enregistrerPropositions(fournisseurId, propositionId, familleId, propositions) {
  return service.put("/propositions/Fournisseur/" + fournisseurId + "/Enregistrer/" + propositionId + "/" + familleId, propositions)
}

export function envoyerPropositions(fournisseurId, objParam) {
  return service.patch("/propositions/fournisseur/" + fournisseurId + "/Envoyer", objParam)
}

export function importerPropositions(fournisseurId, propositionId, fichier) {
  return service.post("/propositions/fournisseur/" + fournisseurId + "/ImporterTarif/" + propositionId, fichier)
}

export function copierFichesTechniques(fournisseurId, propositionId, familleId) {
  return service.post("/propositions/fournisseur/" + fournisseurId + "/CopierFichesTechniques/" + propositionId + "/" + familleId)
}

export function importerFichesTechniques(fournisseurId, propositionId, familleId, fichier) {
  let url = "/propositions/fournisseur/" + fournisseurId + "/ImporterFichesTechniques/" + propositionId
  if (familleId) {
    url += "/" + familleId
  }
  return service.post(url, fichier, {maxContentLength: Infinity, maxBodyLength: Infinity})
}

export function importerUneFicheTechnique(fournisseurId, propositionId, propoArticleId, fichier) {
  return service.post("/propositions/fournisseur/" + fournisseurId + "/ImporterUneFicheTechnique/" + propositionId + "/" + propoArticleId, fichier, {maxContentLength: Infinity, maxBodyLength: Infinity})
}

export function obtenirFicheTechnique(fournisseurId, articlePropoId) {
  return service.get("/propositions/fournisseur/" + fournisseurId + "/ObtenirFicheTechnique/" + articlePropoId, { responseType: "blob" })
}