<template>
  <input :id="id" ref="input"
  type="text" autocomplete="off"
  :class="['number-input', {'bordure-rouge': invalideParcequeVide}]"
  :style="(disabled === true ? 'border-color:transparent!important;' : '') + (focused ? 'direction: ltr' : 'direction: rtl; text-indent: 2px')"
  :value="domValue"
  :required="invalideSiVide"
  :disabled="disabled === true"
  :title="toolTip"
  @click.stop
  @input="handleInput"
  @focus="handleFocus"
  @blur="handleBlur"
  @keyup="handleKey" 
  @keyup.enter.prevent="handleSave">
</template>

<script>
import { arrondiNombre, arrondiMercuriale, deplacerCurseur } from '@/helpers/utils'
export default {
  name: 'InputNumber',
  // invalideSiVide : affiche une bordure si il est vide
  props: ['value', 'typeFiltre', 'id', 'zeroSiVide','invalideSiVide', 'decimales', 'disabled', 'fixBlur', 'toolTip'],
  data() {
    return {
      domValue: this.value ? this.value.toString().replace('.', ',') : this.value,
      savedValue: this.value ? this.value.toString().replace(',', '.') : this.value,
      tmpValue: this.value ? this.value.toString().replace('.', ',') : this.value,
      focused: false
    }
  },
  computed: {
    invalideParcequeVide() {
      return this.invalideSiVide && !this.value && this.value !== 0
    },
  },
  watch: {
    value(newValue) {
      if (!this.fixBlur || this.domValue !== newValue) {
        if (!newValue) {
          this.$nextTick(() => this.domValue = null)
        }

        if (newValue) {
          this.$nextTick(() => {
            //console.log(newValue)
            this.domValue = newValue.toString().replace('.', ',')
          })
        }
        else if (this.zeroSiVide) {
          this.$nextTick(() => this.domValue = 0)
        }
      }
    }
  },
  methods: {
    handleInput(e) {
      if (this.fixBlur) {
        this.tmpValue = arrondiNombre(e.target.value, this.decimales || 3)
      } else {
        let value = e.target.value.replace(',', '.')
        if (this.zeroSiVide && (value === '' || isNaN(value))) {
          value = 0
        }
        if (value !== e.target.value) {
          e.target.value = value
        }
        this.$emit('input', arrondiNombre(value, this.decimales || 3))
      }
    },
    handleFocus(e) {
      this.focused = true
      if (this.fixBlur) {
        this.tmpValue = arrondiNombre(e.target.value, this.decimales || 3)
      }
      e.target.select()
      this.$emit('focus')
    },
    handleBlur() {
      if (this.fixBlur) {
        this.$emit('input', this.tmpValue)
      }
      this.handleSave()
      this.focused = false
    },
    handleSave() {
      if (Number(this.value) !== Number(this.savedValue)) {
        this.savedValue = this.value
        this.$emit('save', this.value)
      } else {
        this.$emit('blur', this.value)
      }
    },
    handleKey(e) {
      let deplacement = deplacerCurseur(e, this.fixBlur)
      if (deplacement) {
        this.handleBlur()
      }
      if (deplacement?.event === "enter") {
        this.domValue = arrondiMercuriale(this.tmpValue)
        e.target.value = arrondiMercuriale(this.tmpValue)
        e.target.selectionStart = 0
        e.target.selectionEnd = e.target.value.length
        this.$emit("move", { direction: null, id: this.id })
        this.focused = true
      } else if (deplacement?.event === "move") {
        this.$emit(deplacement.event, { direction: deplacement.direction, id: this.id })
      }
    },
    filtre(typeFiltre) {
      switch (typeFiltre) {
        case 'montant':
          return function (value) {
            return /^\d*[.,]?\d{0,6}$/.test(value)
          }
        case 'quantite':
          return function (value) {
            return /^\d*[.,]?\d{0,3}$/.test(value)
          }
        case 'quantiteEntiere':
          return function (value) {
            return /^\d*$/.test(value)
          }
        default:
          throw "Filtre " + typeFiltre + " inconnu"
      }
    }
  },
  mounted() {
    let input = document.getElementById(this.id)
    if (input) {
      ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach((event) => {
        let filtreActuel = this.filtre(this.typeFiltre)
        input.filtreActuel = filtreActuel
        input.addEventListener(event, function (event) {
          let filtre = event.target.filtreActuel
          if (filtre(this.value)) {
            this.oldValue = this.value
            this.oldSelectionStart = this.selectionStart
            this.oldSelectionEnd = this.selectionEnd
          } else if ('oldValue' in this) {
            // eslint-disable-next-line vue/no-mutating-props
            this.value = this.oldValue
            this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd)
          }
        })
      }, this)
      let inputValue = document.getElementById(this.id).value
      let replacedValue = inputValue.replace('.', ',')
      document.getElementById(this.id).value = replacedValue
    }
  }
}
</script>
