<template>
  <div class="container-fluid">
    <BarreActions :actions="actions" @appliquer-action="executerAction($event)" />

    <div class="row">
      Texte
    </div>
  </div>
</template>
<script>
import { connecteData } from "@/mixins/connecteData"
import BarreActions from '@/components/Divers/BarreActions'

export default {
  name: "profil",
  mixins: [ connecteData ],
  components: { BarreActions },
  data() {
    return {
      actions: {
        // Boutons à afficher dans la barre d'action
        // Exemple :
        /*
        nomAction: {
          label: Label affiché sur le bouton (string),
          disabled: Etat actif/inactif par défaut du bouton (bool),
          class: Classe CSS (btn-primary, btn-success, btn-warning ou btn-danger) à appliquer sur le bouton (par défaut : "btn-primary" est appliqué => il s'agit du bouton bleu Agap'pro) (string)
          methode(contexte) {
            // => contexte équivaut à this (mais this ne fonctionne pas car l'initialisation de data n'est pas encore terminée, donc data n'est pas encore rattaché à this)
            traitements à exécuter lors du clic sur le bouton
            les traitements définis dans cette page doivent être appelés sous la forme : contexte.traitement()

            Remarque : pour que le système fonctionne, il faut déclarer la méthode ci-dessous dans la section "methods" de cette page
            executerAction(methode) {
              methode(this)
            }

          }
        }
        */
        annuler: {
          label: "Annuler",
          disabled: true,
          class: "btn-danger",
          methode() {
            
          }
        },
        enregistrer: {
          label: "Enregistrer",
          disabled: true,
          class: "btn-success",
          methode() {
            
          }
        },
        // Appeler "this.actions.initialiser(this)"" dans mounted() => génère les tableaux d'actions utilisés pour afficher les boutons à gauche et à droite de la barre d'actions
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.actions.barreGauche = [ ]
          contexte.actions.barreDroite = [ contexte.actions.annuler, contexte.actions.enregistrer ]
        }
        // A gérer en cas de boutons à activer/désactiver (créer une méthode par groupe de boutons à activer/désactiver)
        /*disable(actions, disabled) {
          let actionsATraiter = [ actions.ouvrir, actions.renommer, actions.dupliquer, actions.supprimer ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        }*/
      }
    }
  },
  
  computed: {
    
  },
  validations: {
    
  },
  methods: {
    executerAction(methode) {
      // Nécessaire pour faire fonctionner les actions de la barre d'actions'
      methode(this)
    },
  },
  created() {
    
  }
}
</script>

