import Vue from 'vue'

//Gestion du fournisseur choisi si plusieurs possibles
const profils = {
  namespaced: true,
  state: {
    fournisseur: {
      fournisseurId: null,
      userId: null
    }
  },
  getters: {
    fournisseurCourant: state => {
      return state.fournisseur
    },
    fournisseurCourantId: state => {
      if (state.fournisseur) {
        return state.fournisseur.fournisseurId
      }
      return undefined
    },
    
    fournisseurId(state) {
      return state.fournisseur.fournisseurId
    },
    fournisseurUserId(state) {
      return state.fournisseur.userId
    }
  },
  mutations: {
    setFournisseur(state, payload) {
      Vue.set(state, 'fournisseur', payload)
    },

    emptyFournisseurId(state) {
      Vue.set(state.fournisseur, 'fournisseurId', null)
    }
  }
}

export default profils
